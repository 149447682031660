import db from '@/firebase/init'
import axios from 'axios'


/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const state = {
    data: {
        selected: [],
        message: "",
    },
    status: {
        sending: false,
        error: null,
    }
}


/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
    sendingState(state, bol) {
        state.status.sending = bol
    },
    setError(state, payload) {
        state.status.error = payload
    },
    clearState(state) {
        state.data.message = ""
        state.data.selected = []
    }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {
    async sendPushNotification ({ state, commit, dispatch }) {
        commit('sendingState', true)

        let tokenIds = []
        let userIds = []

        let promises = []

        const userRef = db.collection('users')

        state.data.selected.forEach(async val => {
            if(val === 'dietitian') {
                promises.push(userRef.where('role', '==', 'dietitian').get())
            }
            if (val === 'personal_trainer') {
                promises.push(userRef.where('role', '==', 'personal_trainer').get())
            }

            if (val === 'member') {
                promises.push(userRef.where('role', '==', 'member').get())
            }

            if (val === 'admin') {
                promises.push(userRef.where('role', '==', 'admin').get())
            }

            if(val === 'nutritionist') {
                promises.push(userRef.where('role', '==', 'nutritionist').get())
            }

            if(val === 'naturopath') {
                promises.push(userRef.where('role', '==', 'naturopath').get())
            }
        })

        Promise.all(promises).then((users) => {
            users.forEach(user => {
                user.docs.forEach(doc => {
                    if(doc.data()?.tokenId) {
                        tokenIds.push(doc.data()?.tokenId)
                        userIds.push(doc.id)
                    }
                })
            })


            axios.post('https://us-central1-cq-nutrition.cloudfunctions.net/notification-sendPushNotifications', {
                message: state.data.message,
                tokens: tokenIds
            })
            .then(() => {
                commit('sendingState', false)
                commit('clearState')
                dispatch('showSuccess', 'Successfully sent the notification', { root: true })
            })
            .catch(() => {
                dispatch('showError', 'Something went wrong', { root: true })
            })
        })


    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}