import db from '@/firebase/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const initialState = () => {
    return {
        nutritionTags: [
            'Gluten Contains', 
            'Wheat Contains', 
            'Dairy Contains', 
            'Egg Contains', 
            'Nuts Contains',
            'Seafood Contains',
            'Soy Contains',
            'Histamines Moderate to High',
            'Oxalates Moderate to High',
            'Glutamates Moderate to High',
            'Amines Moderate to High',
            'Salicylates Moderate to High',
        ],
        mealTags: [
            'Vegetarian Friendly',
            'Vegan Friendly',
            'Pescatarian Friendly',
            'Halal Friendly',
            'Paleo Friendly',
            'Mediterranean or Anti-inflammatory',
        ],
        others: [
            'GOS (LKSL)',
            'Fructans (LKSL)',
            'Fructose (LKSL)',
            'Lactose (LKSL)',
            'Sorbitol (LKSL)',
            'Mannitol (LKSL)'
        ],
        status: {
            updating: false
        }
    }
}

const state = initialState()


/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
    updatingState(state, bol) {
        state.status.updating = bol
    }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
    async updateOneFood({ state, commit, dispatch }, food) {
        commit('updatingState', true)

        const nutritionTagsToUpdate = {}
        const mealtagsToUpdate = {}
        const othersToUpdate = {}

        state.nutritionTags.forEach(tag => { nutritionTagsToUpdate[tag] = food[tag] })

        state.mealTags.forEach(tag => { mealtagsToUpdate[tag] = food[tag] })

        state.others.forEach(other => { othersToUpdate[other] = food[other] ? food[other] : false})

        await db.collection('foods').doc(JSON.stringify(food.__id__)).update({
            ...nutritionTagsToUpdate,
            ...mealtagsToUpdate,
            ...othersToUpdate,
            hasTags: true
        })

        commit('updatingState', false)
        dispatch('showSuccess', `Updated the food ${food.name}`, { root: true })
    },

    async updateAllFood ({ state, commit, dispatch }, foods) {
        commit('updatingState', true)

        const length = Math.ceil(foods.length / 500)

        for(let i=0; i < length; i++) {
            let batch = db.batch()
            foods.slice(i * 500, (i * 500) + 500).forEach(food => {
                let id = typeof food.__id__ === 'number' ? JSON.stringify(food.__id__) : food.__id__;

                let nutritionTagsToUpdate = {}
                let mealtagsToUpdate = {}
                let othersToUpdate = {}

                state.nutritionTags.forEach(tag => { nutritionTagsToUpdate[tag] = food[tag] })

                state.mealTags.forEach(tag => { mealtagsToUpdate[tag] = food[tag] })

                state.others.forEach(other => { othersToUpdate[other] = food[other] ? food[other] : false })

                let ref = db.collection('foods').doc(id)
                batch.update(ref, { 
                    ...nutritionTagsToUpdate,
                    ...mealtagsToUpdate,
                    ...othersToUpdate,
                    hasTags: true
                })
            })

            await batch.commit()
        }


        commit('updatingState', false)
        dispatch('showSuccess', 'Updated all the food', { root: true })
    },

    updateAFood({ commit, dispatch }, food) {
        commit('updatingState', true)

        return new Promise((res, rej) => {
            food.ref.update({
                ...food,
                updated: firebase.firestore.Timestamp.now()
            })
            .then(() => {
                commit('updatingState', false)
                dispatch('showSuccess', 'Food updated', { root: true })
                res()
            })
            .catch((error) => {
                console.log(error.message)
                commit('updateStatus', { updating: false })
                dispatch('showError', error.message, { root: true })
                rej()
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}