import Vue from 'vue'
import Vuex from 'vuex'
import { omit } from 'lodash'

Vue.use(Vuex)

import user from './modules/user'
import tags from './modules/tags'
import goal from './modules/goal'
import mail from './modules/mail'
import excel from './modules/excel'
import login from './modules/login'
import foods from './modules/foods'
import recipe from './modules/recipe'
import rating from './modules/rating'
import report from './modules/report'
import clients from './modules/clients'
import members from './modules/members'
import recipes from './modules/recipes'
import pricing from './modules/pricing'
import register from './modules/register'
import settings from './modules/settings'
import mealplans from './modules/mealplans'
import categories from './modules/categories'
import actionList from './modules/actionList'
import ingredients from './modules/ingredients'
import file_upload from './modules/file_upload'
import marketplace from './modules/marketplace'
import notification from './modules/notification'
import measurements from './modules/measurements'
import organisation from './modules/organisation'
import subscriptions from './modules/subscriptions'
import nutritiontags from './modules/nutritionTags'

export default new Vuex.Store({
  /*------------------------------------------------------------------------------
   * STATE
   *----------------------------------------------------------------------------*/
  state: {
    loading: false,
    routeName: null,
    errorMessage: null,
    successMessage: null,
  },

  /*------------------------------------------------------------------------------
   * MUTATIONS
   *----------------------------------------------------------------------------*/
  mutations: {
    setSuccessMessage(state, message) {
      state.successMessage = message

      setTimeout(() => {
        state.successMessage = null
      }, 3000)
    },

    setErrorMessage(state, message) {
      state.errorMessage = message

      setTimeout(() => {
        state.errorMessage = null
      }, 3000)
    },

    loadingState(state, bol) {
      state.loading = bol
    },

    updateRouteName(state, name) {
      state.routeName = name
    }
  },

  /*------------------------------------------------------------------------------
   * ACTIONS
   *----------------------------------------------------------------------------*/
  actions: {
    showSuccess({ commit }, message) {
      commit('setSuccessMessage', message)
    },

    showError({ commit }, message) {
      commit('setErrorMessage', message)
    },

    showLoading({ commit }, bol) {
      commit('loadingState', bol)
    },

    saveDocument({ dispatch }, doc) {
      doc.ref.update(omit(doc, ['ref', 'id', 'userdata']))
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MODULES
   *----------------------------------------------------------------------------*/
  modules: {
    user,
    tags,
    goal,
    mail,
    login,
    foods,
    excel,
    recipe,
    rating,
    report,
    members,
    recipes,
    pricing,
    clients,
    register,
    settings,
    mealplans,
    categories,
    actionList,
    ingredients,
    file_upload,
    marketplace,
    organisation,
    measurements,
    notification,
    subscriptions,
    nutritiontags,
  }
})
