import router from '@/router'
import db from '@/firebase/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const initialState = () => {
  return {
    data: {},
    status: {
      error: null,
      validating: false,
      resetSent: null,
    }
  }
}

const state = initialState()

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  setValidatingState(state, bol) {
    state.status.validating = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  clearPassword(state) {
    state.data.password = ''
  },

  setResetStatus(state, message) {
    state.status.resetSent = message
  },

  resetState(state) {
    Object.assign(state, initialState())
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {
  /*------------------------------------------------------------------------------
   * LOGIN USER
   *----------------------------------------------------------------------------*/
  loginUser({ commit, state, dispatch, rootState }) {
    commit('setValidatingState', true)
    if (state.status.error) commit('setError', null)

    firebase.auth().signInWithEmailAndPassword(state.data.email, state.data.password)
    .then(() => {
      firebase.auth().onAuthStateChanged(() => {
        let user = firebase.auth().currentUser

        if (user) {
          db.collection('logins').add({
            user: user.uid,
            date: Date.now()
          })
          .then(() => {
            if (rootState.user.user) {
              if (['dietitian', 'nutritionist', 'naturopath'].includes(rootState.user.user.role)) router.push({ name: 'Recipes' })
              if (['personal_trainer'].includes(rootState.user.user.role)) router.push({ name: 'MealPlans' })
              else router.push({ name: 'Home' })
            } else {
              Promise.resolve(dispatch('user/getUserData', {}, { root: true }))
              .then(doc => {
                const user = doc.data()
                if (['dietitian', 'nutritionist', 'naturopath'].includes(user.role)) router.push({ name: 'Recipes' })
                if (['personal_trainer'].includes(user.role)) router.push({ name: 'MealPlans' })
                else router.push({ name: 'Home' })
              })
            }

            // Check if on mobile
            if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)){
              dispatch('user/toggleDrawer', {drawer: false}, {root: true})
            }
            
            dispatch('showSuccess', 'You are successfully logged in.', { root: true })
          })
        }
      })
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setValidatingState', false)
      commit('clearPassword')
    })
  },

  /*------------------------------------------------------------------------------
   * RESET PASSWORD
   *----------------------------------------------------------------------------*/
  resetPassword({ commit, state }) {
    commit('setValidatingState', true)
    if (state.status.error) commit('setError', null)

    firebase.auth().sendPasswordResetEmail(state.data.email)
    .then(() => {
      commit('setResetStatus', 'Reset email sent. Please check your inbox or spam folder.')
    })
    .catch(error => {
      commit('setError', error.message)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
