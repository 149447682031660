import db from '@/firebase/init'
import firebase from 'firebase'
import moment from 'moment'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  payments: [],
  subscriptions: [],
  status: {
    getting: false,
    subscribing: false,
    paymentError: null,
    cancelling: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  /*------------------------------------------------------------------------------
   * ACTIVE SUBSCRIPTIONS
   *----------------------------------------------------------------------------*/
  active: function (state, getters, rootState, rootGetters) {

    if (state.subscriptions.length) {
      let subs = state.subscriptions.filter(sub => {
        let active = false


        if (sub.status == 'canceled' || sub.status == 'past_due') {

          let end = moment(sub.current_period_end.toDate())

          if (moment().isBefore(end)) {
            active = true
          }
        }

        return sub.status == 'active' || active
      })

      let sub = null

      if (subs.length) {
        sub = subs[0]
        let plan = rootGetters['pricing/plan'](sub.metadata.plan)
        sub.plan = plan
      }

      return sub
    }
    else {
      return null
    }
  },

  /*------------------------------------------------------------------------------
   * SUBSCRIBED
   *----------------------------------------------------------------------------*/
  subscribed: function (state, getters, rootState) {
    let user = rootState.user.user

    if (user?.role == 'admin') return true

    if (user && user.role == 'member') {
      return !!getters.active || !!user.mealSlots
    }
    else if (user && ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(user.role)) {
      if(user.mealSlots == -1) return true
      return !!getters.active
    }
    else return false
  },

  isOnModerateOrSuper: function (state, getters, rootState) {
    let user = rootState.user.user
    const planIds = ['price_1MEWQ4JA31HAaQY1cyWKxMvi', 'price_1MEWTEJA31HAaQY1uQfUngDE', 'price_1OXDmSJA31HAaQY1xL7QNGVH']

    if (user.mealSlots < 0) return true

    const activePlan = state.subscriptions.filter(sub => sub.status == 'active')

    if (!activePlan.length) return false

    return planIds.includes(activePlan[0].plan.subscription)
  },

  isOnSuper: function (state, getters, rootState) {
    let user = rootState.user.user
    const planIds = ['price_1MEWTEJA31HAaQY1uQfUngDE']

    if (user.mealSlots < 0) return true

    const activePlan = state.subscriptions.filter(sub => sub.status == 'active')

    if (!activePlan.length) return false

    return planIds.includes(activePlan[0].plan.subscription)
  },

  isOnLow: function (state, getters, rootState) {
    let user = rootState.user.user
    const planIds = ['price_1MEWP9JA31HAaQY18MvkJsY4']

    if (user?.mealSlots < 0) return false

    const activePlan = state.subscriptions.filter(sub => sub.status == 'active')

    console.log(activePlan)

    if (!activePlan.length) return false

    return planIds.includes(activePlan[0]?.items[0]?.plan?.id)
  },

  /*------------------------------------------------------------------------------
   * PREVIOUS SUBSCRIPTIONS
   *----------------------------------------------------------------------------*/
  prevs: function (state) {
    if (state.subscriptions.length) {
      return state.subscriptions.filter(sub => {
        let due = moment(sub.due)
        let diff = due.diff(moment(), 'days')
        return diff <= 0
      })
    }
    else {
      return []
    }
  },
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  setGettingState(state, bol) {
    state.status.getting = bol
  },

  addSubscriptions(state, payload) {
    state.subscriptions = []

    payload.forEach(item => {
      let data = Vue.prototype.$formatData(item)
      state.subscriptions.push(data)
    })

    state.status.getting = false
  },

  addSubscription(state, payload) {
    let data

    try {
      data = Vue.prototype.$formatData(payload)
    }
    catch {
      data = payload
    }

    state.subscriptions.push(data)
  },

  setSubscriptions(state, payload) {
    state.subscriptions = []
    if (payload.size) {
      payload.forEach(doc => {
        let sub = Vue.prototype.$formatData(doc)

        if (!state.subscriptions.find(s => s.id == sub.id))
          state.subscriptions.push(sub)
      })
    }

    state.status.getting = false
  },

  setPayments(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        let sub = Vue.prototype.$formatData(doc)

        if (!state.payments.find(s => s.id == sub.id) && sub?.prices?.length)
          state.payments.push(sub)
      })
    }

    state.status.getting = false
  },

  insertSubscription(state, payload) {
    if (!state.subscriptions.find(sub => sub.id == payload.id))
      state.subscriptions.push(payload)

    state.status.getting = false
  },

  clearSubscriptions(state) {
    state.subscriptions = []
  },

  cancelState(state, bol) {
    state.status.cancelling = bol
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET SUBSCRIPTIONS
   *----------------------------------------------------------------------------*/
  getSubscriptions({ commit, dispatch }, uid = null) {
    commit('updateStatus', { getting: true })

    let user = firebase.auth().currentUser || { uid }

    db.collection('customers')
      .doc(user.uid)
      .collection('subscriptions')
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          commit('setSubscriptions', snapshot)

          snapshot.forEach((doc) => {
            dispatch('pricing/getPriceByRef', doc.data().price, { root: true })
            if (doc.data().metadata.plan) dispatch('pricing/getPlan', doc.data().metadata.plan, { root: true })
          })
        }
        else commit('updateStatus', { getting: false })
      })
      .catch(error => {
        console.log(error)
        commit('updateStatus', { getting: false })
        dispatch('showError', error, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * GET PAYMENTS
   *----------------------------------------------------------------------------*/
  async getPayments({ commit, dispatch, rootState }, id = null) {
    let user = firebase.auth().currentUser || { uid: id}
    const userObj = rootState.user.user

    /**
     * TODO: OPTIMIZE THIS FUNCTION
     */

    await db.collection('customers')
      .doc(user.uid)
      .collection('subscriptions')
      .where('status', '==', 'active')
      .get()
      .then(async (subSnapshot) => {
        if (subSnapshot.size) {

          await db.collection('customers')
            .doc(user.uid)
            .collection('payments')
            .where('status', '==', 'succeeded')
            .get()
            .then(async (snapshot) => {
              commit('setPayments', snapshot)

              for (let doc of subSnapshot.docs) {
                if (doc.data()?.prices?.length) {
                  await dispatch('pricing/getPriceByRef', doc.data().prices[0], { root: true })
                  if (doc.data().metadata.plan) await dispatch('pricing/getPlan', doc.data().metadata.plan, { root: true })
                }

                if (doc.data().status == 'active' || doc.data().status == 'trialing')
                  dispatch('user/checkPayments', { payments: snapshot, subscription: doc.data() }, { root: true })
              }
            })


          // Send Email after Purchasing
          if (!userObj?.hasReceivedEmail) {
            dispatch('sendEmail')
          }

        }
      })
      .catch(error => {
        console.log(error)
        dispatch('showError', error.message, { root: true })
      })

  },

  // GET TRIALING PAYMENTS (FREE TRIAL)
  async getTrialingPayments({ dispatch, rootState }) {
    const userObj = rootState.user.user

    await db.collection('customers')
      .doc(userObj.id)
      .collection('subscriptions')
      .where('status', '==', 'trialing')
      .get()
      .then(async (subSnapshot) => {
        if (subSnapshot.size) {
          for (let doc of subSnapshot.docs) {
            if (doc.data()?.prices?.length) {
              await dispatch('pricing/getPriceByRef', doc.data().prices[0], { root: true })
              if (doc.data().metadata.plan) await dispatch('pricing/getPlan', doc.data().metadata.plan, { root: true })
            }

            dispatch('user/checkTrialingPayments', { subscription: doc.data() }, { root: true })
          }
        }

        // Send Email after Purchasing
        if (!userObj?.hasReceivedEmail) {
          dispatch('sendEmail')
        }
      })
      .catch(error => {
        console.log(error)
        dispatch('showError', error.message, { root: true })
      })

  },

  /*------------------------------------------------------------------------------
   * CANCEL SUBSCRIPTION
   *----------------------------------------------------------------------------*/
  cancelSubscription({ dispatch }, sub) {
    dispatch('showLoading', true, { root: true })
    var cancelUserSubscription = firebase.functions().httpsCallable('cancelSubscription')

    cancelUserSubscription({ text: sub.id })
      .then(function (result) {
        var message = result.data.text
        console.log(message)
        dispatch('getSubscriptions')
        dispatch('showLoading', false, { root: true })
        dispatch('showSuccess', 'Subscription cancelled', { root: true })

        dispatch('user/updateUserField', { field: 'subscriptionEndDate', value: sub.current_period_end, silent: true }, { root: true })

        dispatch('user/updateUserField', { field: 'showSubscriptionEndDate', value: true, silent: true }, { root: true })

        setTimeout(() => {
          window.location.reload(true)
        }, 1500)

      })
      .catch(function (error) {
        dispatch('showError', error.message, { root: true })
        console.log(error.message)
        dispatch('showLoading', false, { root: true })
      })
  },

  /*------------------------------------------------------------------------------
   * UPDATE SUBSCRIPTION
   *----------------------------------------------------------------------------*/
  updateSubscription({ dispatch }, data) {
    dispatch('showLoading', true, { root: true })
   
    var updateUserSubscription = firebase.functions().httpsCallable('updateSubscription')

    updateUserSubscription(data)
    .then(() => {
      dispatch('showSuccess', 'Subscription updated!', { root: true })

      dispatch('getPayments')

      setTimeout(() => {
        window.location.reload(true)
      }, 4500)
    })
    .catch(function (error) {
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
      dispatch('showLoading', false, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE SUBSCRIPTION
   *----------------------------------------------------------------------------*/
  deleteSubscription({ dispatch }, id) {
    db.collection('customers').doc(id)
      .delete()
      .catch(error => {
        dispatch('showError', error.message, { root: true })
        console.log(error.message)
      })
  },

  /*------------------------------------------------------------------------------
   * SEND EMAIL AFTER PURCHASE 
   *----------------------------------------------------------------------------*/
  sendEmail({ dispatch, rootState }) {
    const { role, email, firstName } = rootState.user.user

    if (['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(role)) {
      let data = {
        recipient: email,
        subject: 'Thank you!',
        message: `Hi ${firstName}, <br/> <br/> 
        Thank you for subscribing to MEALZEE. <br/> <br/> 
        At MEALZEE we are proud supporters of reducing food wastes and helping people in need; this is why we are proud supporters of Ozharvest. <br/> <br/>
        Your subscription has: <br/>
        <ul>
          <li>made it easier for your clients to make better food choices and ultimately improve their health;</li>
          <li>increased customer satisfaction by enabling a more personalised nutrition approach;</li>
          <li>through our partnership with Ozharvest, covered the cost of two meals being delivered to people in need;</li>
          <li>prevented two meals from ending up in landfills and negatively impacting the environment.</li>
        </ul>
        Our mission at MEALZEE is to have a positive impact on the health of our broader community. <br/> <br/>
        Thank you for helping us achieve our mission.
        `
      }

      dispatch('mail/notify', data, { root: true })
      dispatch('updateHasReceivedEmail')
      return
    }

    let data = {
      recipient: email,
      subject: 'Thank you!',
      message: `Hi ${firstName}, <br/> <br/> 
      Thank you for your purchase of meal plan credits. <br/> <br/> 
      At MEALZEE we are proud supporters of reducing food wastes and helping people in need; this is why we are proud supporters of Ozharvest. <br/> <br/>
      You purchase of meal plan credits has: <br/>
      <ul>
        <li>covered the cost of two meals being delivered to people in need through our partnership with OzHarvest;</li>
        <li>prevented two meals from ending up in landfills and negatively impacting the environment;</li>
        <li>simplified making healthy food choices, ultimately improving your health;</li>
        <li>saved you time and mental energy planning on what to eat for the week ahead.</li>
      </ul>
      Our mission at MEALZEE is to have a positive impact on the health of our broader community. <br/> <br/>
      Thank you for helping us achieve our mission.
      `
    }

    dispatch('mail/notify', data, { root: true })
    dispatch('updateHasReceivedEmail')
  },

  /*------------------------------------------------------------------------------
   * UPDATE HAS USER RECEIVED EMAIL 
   *----------------------------------------------------------------------------*/
  updateHasReceivedEmail({ rootState }) {
    const { id } = rootState.user.user

    db.collection('users')
      .doc(id)
      .update({ hasReceivedEmail: true })
      .catch(error => {
        console.log(error)
      })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
