import Vue from 'vue'
import db from '@/firebase/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const initialState = () => {
    return {
        clients: [],
        status: {
            error: null,
            adding: false,
            getting: false,
            firstLoad: false
        }
    }
}

const state = initialState()

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
    resetState(state) {
        Object.assign(state, initialState())
    },

    gettingState(state, bol) {
        state.status.getting = bol
    },

    addingState(state, bol) {
        state.status.adding = bol
    },

    setError(state, message) {
        state.status.error = message
    },

    updateStatus(state, payload) {
        state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
    },

    setClients(state, payload) {
        state.clients = []

        payload.forEach(client => {
            let data = Vue.prototype.$formatData(client)
            state.clients.push(data)
        })

        state.status.getting = false
    },
    insertClient(state, payload) {
        let data

        try {
            data = Vue.prototype.$formatData(payload)
        }
        catch {
            data = payload
        }

        if (!state.clients.find(p => p.id == data.id))
            state.clients.push(data)
    },
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {
    async getAllClients({ commit, dispatch }, userId) {
        commit('updateStatus', { firstLoad: true })
        commit('gettingState', true)

        let query = db.collection('clients')
            .where('nutrition_professional', '==', userId)
            .orderBy('createdAt', 'desc')

        await query.get()
            .then(snapshot => {
                if (snapshot.size) {
                    commit('setClients', snapshot)
                }
            })
            .catch(error => {
                console.log(error.message)
                dispatch('showError', 'Something went wrong, please try again', { root: true })
            })
            .finally(() => {
                commit('gettingState', false)
            })
    },

    async create({ state, commit, dispatch }, payload = null) {
        commit('addingState', true)
        if (state.status.error) commit('setError', null)

        let data
        data.createdAt = Date.now()

        data = Object.assign({}, payload)

        const res = await db.collection('clients')
            .add(data)
            .then((docRef) => {
                let newData = data
                newData.ref = docRef
                newData.id = docRef.id

                return { newData, error: false, data }
            })
            .catch(error => {
                commit('setError', error.message)
                console.log(error.message)

                dispatch('showError', 'Something went wrong, please try again', { root: true })

                return { error: true }
            })

        return res
    },

    updateClient({ dispatch }, data) {
        const res = data.client.ref
            .update({
                [data.field]: data.value,
                updated: firebase.firestore.Timestamp.now()
            })
            .then(() => {
                if (!data.silent) dispatch('showSuccess', 'Client updated', { root: true })
            })
            .catch(error => {
                console.log(error.message)
                dispatch('showError', 'Something went wrong, please try again', { root: true })
            })

        return res
    }
}

export default {
    state,
    actions,
    mutations,
    namespaced: true
}