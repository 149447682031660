import algoliasearch from 'algoliasearch'

const algolia = algoliasearch(
  process.env.VUE_APP_ALGOLIA_APP_ID,
  process.env.VUE_APP_ALGOLIA_API_KEY
)

const recipeIndex = algolia.initIndex('recipes')
const userIndex = algolia.initIndex('users')
const marketplaceIndex = algolia.initIndex('marketplace')
const myrecipeIndex = algolia.initIndex('my_recipes')

export {
  recipeIndex,
  userIndex,
  marketplaceIndex,
  myrecipeIndex
}

export default algolia.initIndex('ingredients')
