<template>
  <v-app>
    <template v-if="user && user.status == 'pending'">
      <v-card class="box-shadow mx-auto" width="100%" max-width="750" outlined>
        <v-alert type="info" border="left" class="mb-0">
          Your account is pending for approval.
        </v-alert>
      </v-card>
    </template>
    <template v-else>
      <v-navigation-drawer
        v-if="user"
        v-model="drawer"
        color="primary"
        width="220"
        clipped
        mobile-breakpoint="768"
        :class="{ zIndex: subscribeDialog }"
        fixed
        dark
        app
      >
        <v-list-item>
          <v-list-item-icon class="mr-3">
            <UserPhoto :id="user.id" photoSize="thumb" :size="40" />
          </v-list-item-icon>
          <v-list-item-content v-if="user">
            <v-list-item-title class="font-weight-bold">
              {{ user.firstName }} {{ user.lastName | sliceText }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                user?.isStudent ? user?.secondaryRole : determineRole(user.role)
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item-group dark>
          <v-list-item
            v-for="(nav, i) in allowedNavs"
            :to="{ name: nav.route, query: nav.query }"
            :exact="nav.exact"
            :key="i"
            dense
          >
            <v-list-item-icon class="mr-2">
              <v-icon small>{{ nav.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                {{ nav.text }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip
                color="warning"
                class="float-right"
                x-small
                v-if="getNotifs(nav.notif)"
                >{{ getNotifs(nav.notif) }}</v-chip
              >
            </v-list-item-action>
          </v-list-item>
          <a
            href="https://outlook.office365.com/owa/calendar/ChrisHughes@scood.co/bookings/s/IVGXqh0ZJUK4VBKCVLENUw2"
            target="_blank"
            style="color:transparent"
          >
            <v-list-item dense :exact="false">
              <v-list-item-icon class="mr-2">
                <v-icon small>mdi-calendar-month</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  Book Demo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
          <!-- <div class="mcwidget-embed" data-widget-id="26524500"></div> -->
        </v-list-item-group>
      </v-navigation-drawer>

      <v-main class="pa-0">
        <v-container fluid class="full-height">
          <router-view></router-view>
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import { startCase } from 'lodash';

export default {
  name: 'Home',

  data() {
    return {
      startCase,
      navs: [
        {
          text: 'Home',
          icon: 'mdi-view-dashboard',
          route: 'Home',
          show: ['admin', 'member'],
          query: null,
          exact: true,
        },
        {
          text: 'Members',
          icon: 'mdi-account-group',
          route: 'Members',
          query: { v: 'published' },
          show: ['admin'],
          exact: true,
        },
        {
          text: 'Plans & Pricing',
          icon: 'mdi-credit-card',
          route: 'Pricing',
          query: null,
          show: ['admin'],
          exact: true,
        },
        {
          text: 'Recipes',
          icon: 'mdi-food-fork-drink',
          route: 'Recipes',
          show: [
            'admin',
            'dietitian',
            'member',
            'personal_trainer',
            'nutritionist',
            'naturopath',
          ],
          exact: false,
          query: null,
        },
        // {
        //   text: 'Private Recipes',
        //   icon: 'mdi-food-variant',
        //   route: 'MyRecipes',
        //   show: ['admin'],
        //   exact: false,
        //   query: null,
        // },
        // {
        //   text: 'Recipe Stats',
        //   icon: 'mdi-graph',
        //   route: 'RecipeStats',
        //   show: ['admin', 'dietitian'],
        //   exact: false,
        //   query: null,
        // },
        {
          text: 'Meal Plans',
          icon: 'mdi-calendar',
          route: 'MealPlans',
          show: ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'],
          exact: false,
          query: null,
        },
        {
          text: 'Recipe eBooks',
          icon: 'mdi-food-variant',
          route: 'RecipePlans',
          show: ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'],
          exact: false,
          query: null,
        },
        {
          text: 'Promo Settings',
          icon: 'mdi-bullhorn-variant-outline',
          route: 'PromoEbook',
          // show: ['hello@mealzee.com','globexenterprises@hotmail.com', 'stacey@freedomhealthandwellness.com', 'info@51fit.com.au', 'lexzstulawie@gmail.com', 'coach.kahlia@gmail.com', 'info@strongbodies.com.au', 'simon@squashbrook.com.au', 'sara@mevsme.com.au', 'nutritionforliving.dietetics@gmail.com'],
          show: ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'],
          exact: false,
          query: null,
        },
        {
          text: 'Client Lists',
          icon: 'mdi-account-group',
          route: 'Clients',
          show: ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'],
          exact: false,
          query: null,
        },
        {
          text: 'Meal Type Tags',
          icon: 'mdi-tag-text-outline',
          route: 'MealTags',
          show: ['admin'],
          exact: true,
          query: null,
        },
        {
          text: 'Nutrition Tags',
          icon: 'mdi-tag-heart-outline',
          route: 'NutritionTags',
          show: ['admin'],
          exact: true,
          query: null,
        },
        {
          text: 'Reports',
          icon: 'mdi-alert-circle-outline',
          route: 'Reports',
          show: ['admin'],
          exact: true,
          query: null,
        },
        {
          text: 'Foods',
          icon: 'mdi-food-apple',
          route: 'Foods',
          show: ['admin', 'dietitian', 'nutritionist', 'naturopath'],
          query: null,
        },
        {
          text: 'Settings',
          icon: 'mdi-cog',
          route: 'Settings',
          show: ['admin'],
          exact: true,
          query: null,
        },
        {
          text: 'Subscription',
          icon: 'mdi-diamond-stone',
          route: 'Subscription',
          show: [
            'member',
            'dietitian',
            'personal_trainer',
            'nutritionist',
            'naturopath',
          ],
          exact: true,
          query: null,
        },
        {
          text: 'Goal',
          icon: 'mdi-bullseye-arrow',
          route: 'Goal',
          show: ['member'],
          exact: true,
          query: null,
        },
        {
          text: 'Profile',
          icon: 'mdi-account-edit',
          route: 'Profile',
          show: [
            'member',
            'dietitian',
            'personal_trainer',
            'nutritionist',
            'naturopath',
          ],
          exact: true,
          query: null,
        },
        {
          text: 'Marketplace',
          icon: 'mdi-storefront',
          route: 'Marketplace',
          show: [
            'admin',
            'member',
            'dietitian',
            'personal_trainer',
            'nutritionist',
            'naturopath',
          ],
          exact: true,
          query: null,
        },
        {
          text: 'Upload Excel',
          icon: 'mdi-tray-arrow-up',
          route: 'Excel',
          show: ['admin'],
          exact: true,
          query: null,
        },
        {
          text: 'Notification',
          icon: 'mdi-bell-badge',
          route: 'Notifications',
          show: ['admin'],
          exact: true,
          query: null,
        },
        {
          text: 'Organisations',
          icon: 'mdi-account-group',
          route: 'Organisations',
          show: ['admin'],
          exact: true,
          query: null,
        },
        {
          text: 'Meal Plan Requests',
          icon: 'mdi-tab-plus',
          route: 'MealplanRequests',
          show: ['admin'],
          exact: true,
          query: null,
        },
        {
          text: 'Support',
          icon: 'mdi-help-circle-outline',
          route: 'Support',
          show: [
            'admin',
            'member',
            'dietitian',
            'personal_trainer',
            'nutritionist',
            'naturopath',
          ],
          exact: true,
          query: null,
        },
      ],
      subscribeDialog: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),

    allowedNavs() {
      return this.navs.filter((nav) => {
        return nav.show.includes(this.user.role) || nav.show.includes('all') || nav.show.includes(this.user.email);
      });
    },

    ingredientNotifs: function () {
      return this.$store.state.foods.pendingFoods.length;
    },

    drawer: {
      get() {
        return this.$store.state.user.drawer;
      },
      set(value) {
        return value;
      },
    },
  },

  methods: {
    getNotifs(type) {
      if (type == 'ingredients') {
        return this.ingredientNotifs;
      }
    },
    determineRole(role) {
      return role === 'personal_trainer'
        ? 'Health & Fitness Professional'
        : startCase(role);
    },
  },

  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.subscribeDialog = false;
      }
    },
  },

  created() {
    this.$root.$on('showSubscribeDialog', () => {
      this.subscribeDialog = true;
    });
  },
};
</script>
<style scoped>
.zIndex {
  z-index: 300 !important;
}
</style>
