<template>
  <v-dialog
    :no-click-animation="button"
    :persistent="button"
    v-model="dialog"
    max-width="1000"
    scrollable
  >
    <close-button v-if="!button" @click="closeDialog()" overflow />
    
    <v-card>
      <v-card-title class="font-weight-bold">
        TERMS OF SUPPLY OF RECIPE DEVELOPMENT ON THE MEALZEE DIETITIAN RECIPE APP
      </v-card-title>
      <v-card-text class="px-10 pb-10">
        <p>These are the terms of supply of the services provided on Mealzee Dietitian Recipe App and the Website ("<strong>Terms</strong>") for:</p>
        <div class="a-list">
          <div><span>(a)</span><strong>Dietitians</strong> to publish recipes; and</div>
          <div><span>(a)</span><strong>Subscribers</strong> to access recipes and use the Mealzee Dietitian Recipe App to create meal plans.</div>
        </div>
        <p>We may modify and update these Terms at any time, without notice. Users of the App need to review the Terms from time to time. The amended Terms will then take effect from the next time you log into the App or use the Website.</p>
        <p>In using the App or the Website, you agree to be bound by these Terms as well as any and all general Terms and Conditions and our Privacy Policy that is posted on our Website from time to time. If you do not accept these Terms, you are not permitted to use the App and the Website.</p>
        <p><strong>1. DEFINITIONS</strong></p>
        <p><strong>“Advertising Material”</strong> means any type of advertising material, including without limitation, text, layouts, artwork, graphics, scripts, presentations, drawings, documents, logos, designs, charts, images, photos, videos, registered trade marks, unregistered trade marks and copyright works or other material (including, but not limited to banners, text ads, pop-ups and any other type of a promotional message for the purpose of publicizing products or services), that is uploaded to the App or Website by advertisers;</p>
        <p><strong>“App”</strong> means the Mealzee Dietitian Recipe App which allows Dietitians to add recipes to the App and dietitians, gym operators, personal trainers, naturopaths, nutritionists, health coaches and members of the public can subscribe to the App access recipes and use the App to create meal plans;</p>
        <p><strong>“Beta Testing Program”</strong> means the premium lifetime membership program offered to some Subscribers;</p>
        <p><strong>“Content”</strong> means any and all recipes, written material, layouts, artwork, graphics, drawings, logos, designs, charts, images, photos that Dietitians publish on the App or Website (or both);</p>
        <p><strong>“Dietitian”</strong> means an Accredited Practising Dietitian (APD) with the Dietitians Association of Australia (Dietitians Australia) ABN 34 008 521 480;</p>
        <p><strong>“Dietitian’s Royalties”</strong> means the share of net revenue, as set out in clause 4, derived by us from Subscribers using the recipes created by Dietitians in meal plans, which Dietitian’s Royalties rates are set out on the webform for registration as a Dietitian;</p>
        <p><strong>"GST"</strong> means the goods and services tax as defined in the <em>A New Tax System (Goods and Services Tax) Act 1999</em> (Cth);</p>
        <p><strong>“intellectual property rights”</strong> includes (a) legal rights in unregistered trade marks or registered trade marks under the <em>Trade Marks Act 1995</em> (Cth) or any equivalent statute; (b) rights recognised under the <em>Designs Act 2003</em> (Cth) or any equivalent statute; (c) rights recognised under the <em>Copyright Act 1968</em> (Cth) or any equivalent statute; and (d) rights recognised under the <em>Patent Act 1990</em> (Cth) or any equivalent statute.</p>
        <p><strong>"personal information"</strong> has the same meaning as set out in the <em>Privacy Act 1988</em> (Cth) and includes your name, mobile phone number, email and residential address and other personal information as required by any Webform;</p>
        <p><strong>“Subscribers”</strong> means any dietitian, gym operators, personal trainers, naturopaths, nutritionists, health coach and members of the public that subscribe to access the website, and includes people who are members of the Beta Testing Program;</p>
        <p><strong>“Subscription Fees”</strong> means the weekly, monthly, quarterly, half or full yearly fees paid by Subscribers for access to App;</p>
        <p><strong>“Subscription Term”</strong> means the weekly, monthly, quarterly, half or full yearly period of time for which Subscribers are permitted to access to the App, including any renewal of their subscription;</p>
        <p><strong>“we”</strong>, <strong>“our”</strong> and <strong>“us”</strong> means means Mealzee (ABN 72604010139);</p>
        <p><strong>“Website”</strong> means www.mealzee.com, app.mealzee.com</p>
        <p><strong>“Webform”</strong> means our online webforms that are used to register to access the App, create an account or communicate with us on the Website;</p>
        <p><strong>"you"</strong>, <strong>"your"</strong> means any Dietitian or Subscriber (as indicated by the context) registered to use the App and Website.</p>
        <div class="decimal">
          <ol start="2">
            <li class="reset"></li>
            <li>
              <strong>REGISTRATION</strong>

              <ol>
                <li>In order to use the App, all Subscribers and Dietitians need to register and set up an account.</li>
                <li>To use (or continue to use) the App, Dietitians and Subscribers must provide us with truthful, accurate and complete registration information. If any such information changes, you must immediately update your registration information.</li>
                <li>We have the right to verify the truth and accuracy of any registration information at any time. Please be advised that if any information is determined by us to be misleading, inaccurate or untruthful, we may restrict, deny or terminate your account and/or your access and use of the App and the Website.</li>
                <li>Dietitians and Subscribers are responsible for maintaining the confidentiality of your password or login details and you are fully responsible for all liability and losses resulting from your failure to maintain that confidentiality.</li>
                <li>Dietitians and Subscribers are responsible for all usage and activity on your account with us, including use of the account by any employee, independent contractor, agent or other third party authorised by you. </li>
                <li>Dietitians and Subscribers agree that we may rely on any instruction or request supplied to us on the Webform or by email which is reasonably believed by us to be genuine and to have been sent or presented by a person reasonably believed by us to be authorised to act on your behalf.</li>
                <li>Dietitians and Subscribers must notify us by e-mail at <a href="mailto:hello@mealze.com">hello@mealze.com</a> as soon as you become aware of any confirmed or suspected unauthorised uses of your account, or any confirmed or suspected loss or unauthorised disclosure of your password or login details.</li>
                <li>Any fraudulent, unauthorized or otherwise illegal activity on the App or the Website may be grounds for us to suspend or termination your access to the App and Website, without prejudice to our rights under any other terms of these Terms.</li>
              </ol>  
            </li>
            
            <li>
              <strong>WARRANTIES OF THE DIETITIANS</strong>

              <ol>
                <li>The Dietitians warrant in relation to any recipes provided to us by a Dietitian:</li>
                <div class="a-list">
                  <div><span>(a)</span> they are the exclusive legal and beneficial owners of the copyright in the recipes or they have the permission of the owners of the copyright  and licensing rights in the recipes to permit us to supply the recipes to Subscribers as described in these Terms;</div>
                  <div><span>(b)</span> the recipes do not infringe the copyright of any third party; and</div>
                  <div><span>(c)</span> the disclosure of the recipes to us do not breach any legal, equitable or contractual rights of any thirty party, including but not limited to, a breach of obligations of confidentiality in the recipes.</div>
                </div>
                <li>We may, but are not obligated to, refuse to publish or remove any recipe that we determine in our sole discretion violates these Terms or may be unlawful, offensive, defamatory, obscene or that might violate the rights of, harm, or threaten the safety of users of the recipe or violates a third party’s intellectual property rights. We will not be liable to a Dietitian or any third party for any refusal to publish or removal of any recipe when we determine it necessary, in our sole discretion.</li>
                <li>We reserve the right at any time and from time to time to remove, delete, alter or amend or adapt any recipe provided on the App and that you waive any moral rights or equivalent rights in any jurisdiction in relation to any recipe that you upload or post to the App, or that we can edit, adapt and amend such recipe in our sole discretion in order to meet these Terms or our content policies. You  further consent to our use of such edited recipe on the App notwithstanding that such material alterations to the recipe would otherwise be deemed an infringement of your moral rights in the recipe created by you.</li>
              </ol>
            </li>

            <li>
              <strong>Grant of licence by Dietitians and Dietitian’s Royalties</strong>
              <ol>
                <li>In relation to any Dietitian, in consideration for our agreement to pay you the Dietitian’s Royalties, you grant us [a non-exclusive], worldwide license to reproduce, publish, adapt, perform and communicate your recipes to the public in any form of media or technology including the App, and to reproduce, publish, adapt, perform and communicate your recipe in any form of media or technology for the promotion of the App, including, but not limited to, in print or on any social media platform or any other online communication media.</li>
                <li>No Dietitian’s Royalties will be payable on copies of the recipes uploaded to the Website by a Dietitian:</li>
                <div class="a-list">
                  <div><span>(a)</span> that are presented free of charge to news media organisations, journalists, social media platforms, social media influencers or any other person for the purposes of promoting and marketing the App;</div>
                  <div><span>(b)</span> that are made available to Subscribers that are members of the Beta Testing Program; and</div>
                  <div><span>(c)</span> that are included in free one (1) week meal plans offered as promotions to members of the public and Subscribers for the purposes of promoting and marketing the App.</div>
                </div>
              </ol>
            </li>

            <li>
              <strong>Recipe Pricing & App Marketing</strong>
              <ol>
                <li>We reserve the right to determine:</li>
                <div class="a-list">
                  <div><span>(a)</span> the rate at which Dietitian’s Royalties are calculated, which royalty rate may be increased or reduced at any point in time;</div>
                  <div><span>(b)</span> the fee payable for use of the recipes by Subscribers, which fee may be increased or reduced at any point in time; and</div>
                  <div><span>(c)</span> the time, place, communication medium, method and manner of advertising, promotion and other exploitation of the recipes uploaded by the Dietitians to the App.</div>
                </div>
              </ol>
            </li>

            <li>
              <strong>Limited Licence granted to Subscribers</strong>
              <ol>
                <li>We grant Subscribers a limited, revocable, non-exclusive, non-transferable license to access and use the recipes:</li>
                <div class="a-list">
                  <div><span>(a)</span>for your own individual use in preparing meals and meal plans for yourself; and</div>
                  <div><span>(b)</span>if you are a professional user, to use the recipes to create meal plans for your clients.</div>
                </div>
              </ol>
            </li>

            <li>
              <strong>SUBSCRIBER OBLIGATIONS</strong>

              <ol>
                <li>Subscribers must also pay the Subscription Fees for access to the App.</li>
                <li>You are solely responsible for cancelling your account to use the App. You must cancel in writing by notifying us at <a href="mailto:hello@mealzee.com">hello@mealzee.com</a> and receiving our confirmation.</li>
              </ol>
            </li>

            <li>
              <strong>TERMINATION & SUSPENSION OF ACCESS TO THE APP</strong>

              <ol>
                <li>We have the right to terminate the registration of any Dietitians and Subscribers on the App and Website for any reason, at any time for breach of these Terms. Dietitians and Subscribers agree that we may, in our sole discretion, terminate or suspend your access to the App and Website with or without notice and for any reason, including, without limitation, breach of these Terms. Any suspected fraudulent, abusive or illegal activity may be grounds for terminating your relationship and may be referred to the appropriate law enforcement authorities.</li>
                <li>Upon such suspension or termination, regardless of the reasons, the right of a Dietitians and Subscribers to use the App or Website immediately ceases and you acknowledge and agree we may immediately suspend your access to, deactivate or delete your account and all related information and files. We will not be liable to a Dietitians and Subscribers or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection with such termination or suspension.</li>
                <li>We are not required to provide any refund any Subscription Fees or part thereof to a Subscriber for such suspension or termination of your account to use the App. Any refund will be determined in our sole discretion.</li>
              </ol>
            </li>

            <li>
              <strong>PROCESSING OF PAYMENTS</strong>

              <ol>
                <li>By providing your credit card or debit card details, Subscribers authorise us to deduct the Subscription Fees or Course Fees from your credit card or other nominated payment method.</li>
                <li><em>We do not process any payments on the App or Website or store credit card information on the App or Website, in the event payment transactions occur on our App or Website, we pass credit card information to PayPal or Stripe (third-party payment processors) using secure payment technology and PayPal processes the transaction and send us the confirmation of payment. For further information about PayPal or Stripe visit their website.</em></li>
              </ol>
            </li>

            <li>
              <strong>SUPPLY OF RECIPES AND MEAL PLANS</strong>

              <ol>
                <li>We are acting as a neutral facilitator only by providing an information delivery service of recipes and meal plans. We provide the recipes to assist Subscribers to provide the meal plans or for individuals to use the recipes and meal plans.</li>
                <li>Results of a person’s diet vary from individual to individual, as performance, progress and success of any diet, recipe or meal plan are dependent on human physiology and individual attributes and other factors beyond our control. We cannot and do not guarantee any particular or any results from the use of the recipes and meal plans.</li>
                <li>Subscribers need to make their own enquiries and analysis to determine if any recipe and meal plan is suitable for your own particular purposes and suitable for your clients, including but not limited food allergies or medical conditions that may be affected by consumption of certain foods.</li>
              </ol>  
            </li>

            <li>
              <strong>DISCLAIMERS AND LIMITATION OF LIABILITY</strong>

              <ol>
                <li>The recipes and other information published on the App or the Website (or both) is for general information purposes only. While we endeavour to keep the recipes and other information published on the App or the Website (or both) relevant for healthy nutrition, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability for any purpose. Any reliance a Subscriber may place on such recipes and information is therefore strictly at their own risk. A Subscriber needs to make their own enquiries to determine if the recipes and other information published on the App or the Website (or both) are appropriate for the intended use.</li>
                <li>It is the responsibility of Subscribers to ensure that any recipes and other information available through the App or the Website meet your specific, personal requirements. Subscribers acknowledge that such recipes, information and materials may contain inaccuracies, errors or omissions and we expressly exclude liability for any such inaccuracies, errors or omissions to the fullest extent permitted by law. Subscribers use of, or reliance on, any information or materials on the App or Website is entirely at your own risk, for which we are not liable.</li>
                <li>It is an essential pre-condition to Subscribers using the App or Website that you agree and accept that we are not legally responsible for any direct, indirect, consequential or incidental loss or damage which may result from your use of the App or Website (or both). In no event will we be liable for any consequential, indirect, incidental or special damages of any kind including any damages for injury, death, loss of income or profits, interruption of business, even if the possibility of such loss was made known to us.</li>
                <li>Certain legislation including the Australian Consumer Law (ACL) in the <em>Consumer and Competition Act 2010</em> (Cth), and similar consumer protection laws and regulations may confer you with rights, warranties, guarantees and remedies relating to the provision of the recipes and meal plans to you which cannot be excluded, restricted or modified ("<strong>Statutory Rights</strong>").</li>
                <li>Our liability is governed solely by the ACL and these Terms and we exclude liability under all conditions, warranties or guarantees implied by custom, law or statute except for your Statutory Rights.</li>
                <li>Except for the Statutory Rights, recipes and meal plans are provided to Subscribers without warranties of any kind, either express or implied; and we expressly disclaim all warranties of any kind including but not limited to implied warranties of that the recipes and other information will be provided with due care and skill and fitness for a particular purpose.</li>
                <li>
                  When the Statutory Rights apply, to the extent possible, our liability in respect of any claim is limited to, at our option:

                  <div class="a-list">
                    <div><span>(a)</span> The supply of any of the recipes and other information again; or</div>
                    <div><span>(b)</span> The payment of the cost of having the recipes and other information supplied again.</div>
                  </div>
                </li>
                <li>Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</li>
                <li>No agency, partnership, joint venture, or employment is created as a result of these Terms, and Dietitians and Subscribers do not have any authority of any kind to bind us in any respect whatsoever.</li>
              </ol>
            </li>
            <li>
              <strong>INDEMNITY</strong>

              <ol>
                <li>
                  Dietitians and Subscribers agree to fully indemnify us, our officers, employees, agents and successors, and keep us at all times fully indemnified from and against any claims, demands, costs, damages or awards (direct, indirect, actual, consequential or incidental) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, made by any person or legal entity which arises out of or in any way related to:

                  <div class="a-list">
                    <div><span>(a)</span> your breach of any provision of these Terms; or</div>
                    <div><span>(b)</span> any unauthorised use of the App or the Website by you or anyone obtaining your login information.</div>
                  </div>
                </li>
                <li>
                  Subscribers agree to fully indemnify us, our officers, employees, agents and successors in rights, and keep us at all times fully indemnified from and against any claims, demands, costs, damages or awards (direct, indirect, actual, consequential or incidental) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, made by any person or legal entity which arises out of or in any way related to:

                  <div class="a-list">
                    <div><span>(a)</span> your use of the recipes, meal plans and other information accessed on the App; or</div>
                    <div><span>(b)</span> arising any dealings or any transactions you conduct with any of your clients in relation to the recipes, meal plans and other information accessed on the App.</div>
                  </div>
                </li>
              </ol>
            </li>
            
            <li>
              <strong>CONTENT OF WEBSITE</strong>

              <ol>
                <li>Whilst we have no reason to believe that any information contained on the Website is inaccurate, we do not warrant the accuracy, adequacy or completeness of such information, nor do we undertake to keep the App or Website (or both) updated.</li>
                <li>We may, but are not obligated to, refuse to publish or remove any recipes and other information, that we determine in our sole discretion violates these Terms or may be unlawful, offensive, defamatory, obscene or that might violate the rights of, harm, or threaten the safety of Subscribers and their customers or violates a third party’s intellectual property rights.</li>
              </ol>
            </li>

            <li>
              <strong>ACCESS TO WEBSITE</strong>

              <ol>
                <li>The App and Website uses third party vendors and hosting partners to provide the necessary software, hardware, service and storage.</li>
                <li>We make no warranty that the App or Website (or both) will meet the requirements of any Dietitians or Subscribers, or be available on an uninterrupted, secure or error-free basis. We will use our best endeavors to ensure the App or Website (or both) is always available and virus free but from time-to-time, and in some instances, this may not be the case as it may be out of our immediate control.</li>
                <li>
                  Dietitians and Subscribers agree that you will not do any of the following:

                  <div class="a-list">
                    <div><span>(a)</span> up-load, post or transmit any viruses, malware, trojans, worms or any other forms of malicious computer script to our App or Website (or both);</div>
                    <div><span>(b)</span> use any robot, spider, scraper or other automated means to perform searches on our App or Website (or both); or</div>
                    <div><span>(c)</span> copy, disassemble, decompile or reverse engineer the computer programs and software apps used on our App or Website (or both).</div>
                  </div>
                </li>
              </ol>
            </li>

            <li>
              <strong>EXCLUSION OF COMPETITORS</strong>

              <div>If any Dietitian or Subscriber is in the business of creating similar documents, goods or services for the purpose of providing them for a fee to users, whether they be business users or domestic users, then you are a competitor and you are not permitted to use or access our Website, to download any video material, photographic material, recipes and instructions for creating food or documents or information from our App or Website (or both)  or obtain any such video material, photographic material, recipes, meal plans and instructions for creating food or documents or information through a third party from our App and Website. If any Dietitian or Subscriber breaches this provision, then we will hold you fully responsible for any loss that we may sustain and further hold you accountable for all profits that you might make from such unauthorised use. We reserve the right to exclude and deny any person access to our App or Website (or both) in our sole discretion.</div>
            </li>

            <li>
              <strong>INTELLECTUAL PROPERTY</strong>

              <ol>
                <li>All custom graphics, icons, logos and service names are our registered trademarks, copyright, trade or service marks.</li>
                <li>All other trademarks or service marks within the App or Website (or both) are the property of their respective owners. Nothing in these Terms grants Dietitians or Subscribers any right to use any trademark, service mark, logo, and/or our name or anything you access through our App or Website (or both).</li>
                <li>Dietitians or Subscribers agree and acknowledge that we retain all right, title and interest in the App or Website (or both), including but not limited to the inventions, designs and other intellectual property rights contained or embodied within the App or Website (or both).</li>
                <li>Dietitians or Subscribers are solely responsible for obtaining written permission before re-using any copyrighted material, including but not limited to recipes and meal plans, that is available on the App or Website (or both). Any unauthorised use of the material appearing on the App or Website (or both may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.</li>
              </ol>
            </li>

            <li>
              <strong>CANCELLATION AND TERMINATION OF YOUR ACCESS TO THE APP & WEBSITE</strong>

              <ol>
                <li>
                  Dietitians or Subscribers agree that we may, in our sole discretion, terminate or suspend your access to the App or Website (or both with or without notice and for any reason, including, without limitation:

                  <div class="a-list">
                    <div><span>(a)</span> your breach of these Terms;</div>
                    <div><span>(b)</span> any suspected misleading or deceptive, fraudulent, abusive or illegal activity you engage in, or benefit from, in relation to your use of the Services; and</div>
                    <div><span>(c)</span> your providing false or misleading information when you complete the Webform to subscribe to the the App or Website (or both) or when you change your information.</div>
                  </div>
                </li>
                <li>Upon such termination or suspension of an account of any Dietitian or Subscriber, regardless of the reasons, the right of the Subscriber to access and use the App or Website (or both) immediately ceases and we may immediately deactivate your profile or delete the Subscriber’s account and all other information.</li>
                <li>
                  We will not be liable to the Dietitian or Subscriber, whose account has been terminated or suspended, for:

                  <div class="a-list">
                    <div><span>(a)</span> any refund of fees; and</div>
                    <div><span>(b)</span> any claims or damages made by the Dietitian or Subscriber arising out of any termination or suspension or any other actions taken by us in connection with such termination or suspension.</div>
                  </div>
                </li>
                <li>If Dietitian or Subscriber wishes to cancel their subscription to the App or Website (or both), you must [inform us in writing by email so that we can deactivate your account your account using the customer information Webform on the App or Website (or both)].</li>
                <li>We reserve the right to disclose the name and any other personal information of any Dietitians or Subscribers to any law enforcement authority or other competent authority or to any person for the purpose of legal proceedings, prosecution, investigation or any breach, alleged breach of the law or these Terms.</li>
              </ol>
            </li>
            
            <li>
              <strong>MEDIATION AND DISPUTE RESOLUTION</strong>

              <ol>
                <li>If a dispute arises, the parties agree that confidentiality is paramount to the reputation of both parties. At no time must any communications or discussions be made public, including but not limited to any social media websites of either party. Any public discussion or comments about either party are considered defamatory, negative or otherwise damaging and will be the subject of compensation in any mediation or litigation claim.</li>
                <li>In the event of any dispute on the work, quality or ownership that cannot be resolved, both parties agree to obtain an independent professional arbitrator/dispute resolution specialist to make a determination on the dispute and each of us agrees to pay our own costs.</li>
              </ol>
            </li>
            
            <li>
              <strong>PRIVACY</strong>

              <ol>
                <li>We will comply with the <em>Privacy Act 1988</em> (Cth) and the Australian Privacy Principles (APPs) in relation to personal information, you provide to the App or Website (or both). Please refer to our full privacy policy on this Website for details of how we collect, store and use your personal information.</li>
                <li>We reserve the right to disclose the name and any other personal information of any Dietitians or Subscribers to any law enforcement authority or other competent authority or to any person for the purpose of legal proceedings, prosecution, investigation or any breach or alleged breach of the law or these Terms.</li>
              </ol>
            </li>
            
            <li>
              <strong>PARTIAL INVALIDITY OF PROVISIONS</strong>

              <ol>
                <li>If any provision of these Terms is void, illegal, invalid, or unenforceable in whole or in part, such provision will be severable from all other provisions herein and will not affect or impair the validity or enforceability of any other provisions of this agreement; provided, however, that a court having jurisdiction may revise such provision to the extent necessary to make such provision valid and enforceable.</li>
                <li>Where any provision of these Terms would by any applicable statute be illegal, void, or unenforceable in any State or Territory of Australia then such provision shall not apply in that State or Territory and will be deemed never to have been included in these terms and conditions in that State or Territory. Such a provision if legal and enforceable in any other State or Territory shall continue to be fully enforceable and part of this agreement in those other States and Territories. The deemed exclusion of any term pursuant to this paragraph will not affect or modify the full enforceability and construction of the other provisions of these Terms.</li>
              </ol>
            </li>

            <li>
              <strong>WHOLE AGREEMENT</strong>

              <div>These terms and conditions represent the whole agreement between Dietitians or Subscribers and us concerning your use and access to the App or Website (or both) and your use and access to the Services. No other term is to be included in this agreement except where it is required to be included by any legislation of the Commonwealth of Australia or any State or Territory of Australia.</div>
            </li>

            <li>
              <strong>GOVERNING LAW</strong>

              <div>These Terms are governed by the laws of the State of Queensland, Australia, which are in force from time to time and both you and we agree to submit to the exclusive jurisdiction of the Courts of Queensland for determining any dispute concerning these Terms.</div>
            </li>
          </ol>

          <div>These Terms were last modified on  19 May 2022 and are effective from that date.</div>
        </div>
      </v-card-text>
      <template v-if="button">
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <div class="mr-2">By clicking the button, you are confirming that you have read, understood and agree to our Terms and Conditions</div>
          <v-btn 
            @click="agreeTermsAndConditions()"
            :loading="agreeing"
            color="primary"
          >I Agree</v-btn>
        </v-card-actions>
      </template>
    </v-card> 
  </v-dialog>
</template>

<script>
export default {
  props: {
    button: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
      agreeing: false,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.dialog = true
    },
    
    closeDialog() {
      this.dialog = false
    },

    agreeTermsAndConditions() {
      this.agreeing = true
      
      Promise.resolve(
        this.$store.dispatch('user/updateUserField', {
          field: 'agreeTermsAndConditions',
          value: true,
          message: 'Thank You!'
        })
      )
      .then(() => {
        this.closeDialog()
        this.agreeing = false
      })
    }
  }
}
</script>

<style lang="scss">
  ol {
    li {
      margin-bottom: 1em;
    }
  }

  .decimal {
    ol {
      list-style-type: none;
      counter-reset: section;

      li {
        margin-bottom: 0.6em;

        &.reset {
          opacity: 0;
          line-height: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      li:before {
        counter-increment: section;
        content: counters(section, ".") ". ";
        margin-left: -23px;
      }
    }
  }
  
  .a-list {
    margin-left: 22px;

    & > div {
      position: relative;
      margin-bottom: 0.6em;

      span {
        position: absolute;
        left: -22px;
      }
    }
  }
</style>