import Vue from 'vue'
import _ from 'lodash'
import firebase from 'firebase'
import db from '@/firebase/init'
import { orderBy } from 'lodash'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  tags: [],
  data: {},
  myTags: null,
  status: {
    error: null,
    adding: false,
    getting: false,
    updating: false,
    deleting: false,
    firstLoad: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  getName: (state) => (id) => {
    let tag = state.tags.find(tag => tag.id == id)
    return tag ? tag.name : ''
  },

  tags: (state, getters, rootState) => {
    let user = rootState.user.user
    
    if (user && state.tags.length) {
      let tags = state.tags.filter(tag => tag.published)
      tags = orderBy(tags, 'name', 'asc')

      tags = tags.filter(tag => {
        if (tag.roles)
          return tag.roles.includes(user.role) || user.role == 'admin'
        else return false
      })

      return tags
    }
    else return []
  },
  checkIfUnpublish: (state) => id => {
    let tag = state.tags.find(tag => tag.id == id)

    if(tag)
      return tag.published
    else
      return false
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  setGettingState(state, bol) {
    state.status.getting = bol
  },

  seFirstLoad(state, bol) {
    state.status.firstLoad = bol
  },

  setTags(state, payload) {
    state.tags = []

    payload.forEach(tag => {
      let data = tag.data()
      data.id = tag.id
      data.ref = tag.ref
      state.tags.push(data)
    })

    state.status.getting = false
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  insertTag(state, payload) {
    if (!state.tags.find(tag => tag.id == payload.id)) {
      let data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
      state.tags.push(data)
    }

    state.status.adding = false
    state.data = {}
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setDeletingState(state, bol) {
    state.status.deleting = bol
  },

  removeTag(state, tag) {
    state.tags.splice(state.tags.indexOf(tag), 1)
  },

  setMyTags(state, payload) {
    state.myTags = payload
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  updateValue(state, payload) {
    Vue.set(payload.tag, payload.field, payload.value)
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET ALL TAGS
   *----------------------------------------------------------------------------*/
  async getTags({ commit, dispatch }) {
    commit('setGettingState', true)

    await db.collection('tags').get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setTags', snapshot)
        commit('seFirstLoad', true)
      }
      else {
        commit('setGettingState', false)
        commit('seFirstLoad', true)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * ADD TAG
   *----------------------------------------------------------------------------*/
  addTag({ commit, state, dispatch }) {
    commit('setAddingState', true)
    if (state.status.error) commit('setError', null)

    let user = firebase.auth().currentUser
    let data = state.data
    data.user = user.uid
    data.createdAt = Date.now()
    data.roles = ['dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath']

    db.collection('tags').add(data)
    .then((docRef) => {
      dispatch('getTag', docRef.id)
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setAddingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * GET TAG
   *----------------------------------------------------------------------------*/
  getTag({ commit, dispatch }, id) {
    db.collection('tags').doc(id).get()
    .then(doc => {
      commit('insertTag', doc)
      dispatch('showSuccess', 'Tag successfully added.', { root: true })
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE TAG
   *----------------------------------------------------------------------------*/
  async updateTag({ commit, state, dispatch }, tag) {
      commit('setUpdatingState', true)
      if (state.status.error) commit('setError', false)

      await tag.ref.update(_.omit(tag, ['ref', 'id']))
      .then(() => {
        commit('setUpdatingState', false)
        dispatch('showSuccess', 'Meal successfully updated.', { root: true })
      })
      .catch(error => {
        commit('setError', error.message)
        commit('setUpdatingState', false)
      })
  },

  /*------------------------------------------------------------------------------
   * DELETE TAG
   *----------------------------------------------------------------------------*/
  async deleteTag({ commit, dispatch }, tag) {
    commit('setDeletingState', true)

    await tag.ref.delete()
    .then(() => {
      commit('setDeletingState', false)
      commit('removeTag', tag)
      dispatch('showSuccess', 'Meal tag successfully deleted.', { root: true })
    })
    .catch(error => {
      commit('setDeletingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * SAVE MY TAGS
   *----------------------------------------------------------------------------*/
  saveMyTags({ commit, dispatch }, tags) {
    let user = firebase.auth().currentUser
    
    db.collection('users')
    .doc(user.uid)
    .collection('tags')
    .limit(1).get()
    .then(snapshot => {
      let ref
      let doc = null
      let intersection = []

      if (snapshot.size) {
        doc = snapshot.docs[0]
        ref = doc.ref
        intersection = _.intersection(tags, doc.data().tags)
      }
      else {
        ref = db.collection('users').doc(user.uid).collection('tags').doc()
      }

      if (!doc || (intersection.length !== doc.data().tags.length || intersection.length !== tags.length)) {
        let data = {
          user: user.uid,
          createdAt: Date.now(),
          tags,
        }
  
        ref.set(data)
        .then(() => {
          dispatch('showSuccess', 'Meal tags saved.', { root: true })
          commit('setMyTags', data)
        })
      }

    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log('Error:', error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * GET MY TAGS
   *----------------------------------------------------------------------------*/
  async getMyTags({ commit, dispatch }) {
    let user = firebase.auth().currentUser
    let mytags = null

    await db.collection('users')
    .doc(user.uid)
    .collection('tags')
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        mytags = doc.data()
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        commit('setMyTags', data)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })

    return mytags
  },

  /*------------------------------------------------------------------------------
   * DELETE USER TAGS
   *----------------------------------------------------------------------------*/
  async deleteTags({ dispatch }) {
    let user = firebase.auth().currentUser

    await db.collection('users')
    .doc(user.uid)
    .collection('tags')
    .limit(1).get()
    .then(async (snapshot) => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        await doc.ref.delete()
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE TAG FIELD
   * @PARAMS
   *  tag Object
   *  value Any
   *  field String
   *  message String (optional)
   *  silent Boolean (optional)
   *----------------------------------------------------------------------------*/
  async updateTagField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    
    await data.tag.ref
    .update({
      [data.field]: data.value,
      updated: firebase.firestore.Timestamp.now()
    })
    .then(() => {
      commit('updateValue', data)
      dispatch('showSuccess', 'Tag updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
    .finally(() => {
      commit('updateStatus', { updating: false })
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
