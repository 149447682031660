<template>
  <v-app>
    <template v-if="isTempUrl">
      <div class="pa-5 text-center">
        <h1>404 Not Found</h1>
      </div>
    </template>

    <template v-else>
      <v-app-bar
        v-if="!$route.meta.hideHeader"
        class="box-shadow"
        color="white"
        clipped-left
        flat
        app
        :style="{ zIndex: dialog ? '300' : '5' }"
      >
        <!-- Nav Icon for Toggle  -->
        <div v-if="user" class="d-md-none d-xl-none d-lg-none">
          <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
        </div>
        <div v-else class="d-md-none d-xl-none d-lg-none">
          <v-app-bar-nav-icon
            @click.stop="publicDrawer = !publicDrawer"
          ></v-app-bar-nav-icon>
        </div>

        <div class="d-flex align-center">
          <v-toolbar-title class="font-weight-bold">
            <div class="d-none d-lg-flex d-lg-flex d-md-flex d-sm-flex">
              <v-img
                :src="require('@/assets/mealzee_logo.png')"
                max-width="200"
                contain
              ></v-img>
            </div>
            <div class="d-flex d-sm-none">
              <v-img
                :src="require('@/assets/mealzee_logo.png')"
                max-width="150"
                contain
              ></v-img>
            </div>
          </v-toolbar-title>
        </div>

        <v-spacer></v-spacer>

        <!-- GUEST -->
        <template v-if="!user">
          <div class="d-none d-md-block d-lg-block d-xl-block">
            <v-btn :to="{ name: 'Register' }" class="mr-2" small text>
              <span>Create Account</span>
            </v-btn>

            <v-btn :to="{ name: 'Login' }" text small>
              <v-icon left>mdi-account-circle</v-icon>
              <span>Account</span>
            </v-btn>
          </div>
        </template>

        <!-- IF LOGGED IN -->
        <template v-else>
          <v-chip
            :to="{ name: 'Subscription' }"
            v-if="plan && user.role == 'member'"
            class="mr-2"
            color="info"
            outlined
            small
            label
          >
            <v-icon small left>mdi-diamond-stone</v-icon>
            {{ $store.getters['pricing/price'](plan.price.id).description }}
          </v-chip>

          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-chip
                v-if="user && user.role !== 'admin'"
                :to="{ name: 'Subscription' }"
                class="mr-2"
                v-on="on"
                outlined
                small
              >
                Meal Plan/Recipe eBook:
                <template v-if="$store.getters['subscriptions/active']">
                  <!-- {{ parseInt() == -1 ? 'unlimited' : $store.getters['subscriptions/active'].plan.downloads }} -->
                  {{
                    parseInt(user.mealSlots) == -1
                      ? 'unlimited'
                      : user.mealSlots
                  }}
                </template>
                <template v-else-if="parseInt(user.mealSlots) == -1"
                  >unlimited</template
                >
                <template v-else>{{ user.mealSlots || 0 }}</template>
              </v-chip>
            </template>
            <span class="caption">Number of Meal Plan / Recipe eBooks</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on }">
              <!-- <v-chip
                v-if="user && user.role !== 'admin'"
                :to="{ name: 'Subscription' }"
                class="mr-2"
                v-on="on"
                outlined
                small
              >
                Download Credits:
                <template v-if="$store.getters['subscriptions/active']">
                  {{ parseInt() == -1 ? 'unlimited' : $store.getters['subscriptions/active'].plan.downloads }}
                  {{
                    parseInt(user.downloadCredits) == -1
                      ? 'unlimited'
                      : user.downloadCredits
                  }}
                </template>
                <template v-else-if="parseInt(user.downloadCredits) == -1"
                  >unlimited</template
                >
                <template v-else>{{ user.downloadCredits || 0 }}</template>
              </v-chip> -->
              <v-chip
                v-if="user && user.role !== 'admin'"
                class="mr-2"
                v-on="on"
                outlined
                small
              >
                Subscription:
                <template v-if="$store.getters['subscriptions/active']">
                active
                </template>
                <template v-else>inactive</template>
              </v-chip>
            </template>
            <span class="caption">Subscription Status</span>
          </v-tooltip>

          <v-menu offset-y transition="slide-x-transition" nudge-top left>
            <template v-slot:activator="{ on }">
              <v-btn color="dark" v-on="on" text small>
                <v-icon left>mdi-account-circle</v-icon>
                {{ user.firstName }} {{ user.lastName | sliceText
                }}<v-icon small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list min-width="150">
              <v-list-item dense :to="{ name: 'Profile' }">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-account-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense @click="signOut()">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Signout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-app-bar>
      <v-navigation-drawer
        v-if="!user"
        v-model="publicDrawer"
        color="primary"
        width="220"
        mobile-breakpoint="3440"
        clipped
        fixed
        dark
        app
      >
        <v-list-item-group dark>
          <v-list-item :to="{ name: 'Register' }" dense>
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                Create Account
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Login' }" dense>
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                Account
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

      </v-navigation-drawer>

      <v-main>
        <div class="fill-height pa-lg-7 pa-xl-7">
          <v-alert v-if="user?.showSubscriptionEndDate" type="warning" border="left" text>
            <span class="mr-2"
            >
            You have an active subscription only until <strong><u>{{ moment(user?.subscriptionEndDate.toDate()).format('MMMM DD YYYY') }}</u></strong>
            </span>
            <v-btn
              color="primary"
              small
              depressed
              :to="{ name: 'Subscription' }"
            >
            Update Subscription
            </v-btn>
          </v-alert>
          <router-view></router-view>
        </div>
      </v-main>

      <!-- SUCCESS SNACKBAR -->
      <v-snackbar :value="success" color="success" top>
        <v-icon color="success darken-4" left>mdi-check-circle</v-icon>
        <span class="success--text text--darken-4">{{ success }}</span>
      </v-snackbar>

      <!-- ERROR SNACKBACR -->
      <v-snackbar :value="error" color="dark" dark top>
        <v-icon color="red" left>mdi-alert</v-icon>
        <span>{{ error }}</span>
      </v-snackbar>

      <!-- LOADING DIALOG -->
      <loading />

      <v-footer dark color="secondary" v-if="!$route.meta.hideFooter">
        <v-row class="caption" no-gutters>
          <v-col>
            Copyright 2022 {{ AppTitle }} by
            <a href="https://www.lightningsites.com/" target="_blank"
              >Lightning Sites</a
            >
          </v-col>
          <v-col class="text-right">
            <router-link :to="{ name: 'PrivacyPolicy' }"
              >Privacy Policy</router-link
            >
          </v-col>
        </v-row>
      </v-footer>
    </template>

    <TermsDialog ref="termsDialog" button />

    <v-dialog v-model="subscribeDialog" max-width="500" persistent>
      <!-- <close-button @click="subscribeDialog = false" overflow /> -->

      <v-card>
        <v-card-title> Subscription Required </v-card-title>
        <v-card-text>
          <v-card
            @click="videoDialog = true"
            width="450"
            height="150"
            class="text-center mb-3 pa-5 d-flex flex-column"
            outlined
            hover
          >
            <v-icon size="45" color="accent">mdi-play-circle</v-icon>
            <div class="text-center font-weight-bold">Watch video</div>
            <p>
              You need an active subscription to access this page. If you're
              interested in a free trial, please message us on Facebook.
            </p>
          </v-card>

          <v-btn
            @click="subscribeDialog = false"
            :to="{ name: 'Subscription' }"
            color="primary"
            >Subscribe Here</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="expiredPopUpDialog" max-width="500" persistent>
      <!-- <close-button @click="expiredPopUpDialog" overflow /> -->

      <v-card>
        <v-card-title> Free Trial Expired </v-card-title>
        <v-card-text>
          <p>
            Your Free trial has expired. To continue using Mealzee, you may
            subscribe to our plan.
          </p>

          <v-btn @click="expiredDialog()" color="primary">Subscribe Here</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="videoDialog" width="1200" eager>
      <close-button @click="videoDialog = false" overflow />
      <v-card>
        <!-- PERSONAL USE -->

        <div
          v-if="user?.role == 'member'"
          style="padding: 56.25% 0 0 0; position: relative"
        >
          <iframe
            src="https://player.vimeo.com/video/821488128?h=56ab917a56&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="MEALZEE for Personal Use Overview"
          ></iframe>
        </div>

        <!-- H&FPs -->

        <div
          v-else-if="user?.role == 'personal_trainer'"
          style="padding: 56.25% 0 0 0; position: relative"
        >
          <iframe
            src="https://player.vimeo.com/video/821483992?h=a61ad78d1f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="MEALZEE for H&amp;amp;FPs Overview"
          ></iframe>
        </div>

        <!-- DIETITIAN -->

        <div v-else style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/821479612?h=83b2bb654f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="MEALZEE for Dietitians Overview"
          ></iframe>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      color="white"
      top
      right
      multi-line
      timeout="-1"
    >
      <div class="d-flex flex-start flex-column" style="gap: 0.5rem">
        <div class="d-flex justify-space-between align-top">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fthumb_mealzee_pdf.png?alt=media&token=2112cfa8-085c-48aa-8350-e4029897f51d"
            width="70px"
            height="55px"
          />
          <v-btn color="red" @click="snackbar = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          style="color: rgba(0, 0, 0, 0.6)"
          class="d-flex flex-column flex-2"
        >
          <h3 style="">Notification</h3>

          <div class="d-flex">
            {{ notification }}
          </div>
        </div>
      </div>
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="920px">
      <!-- <close-button @click="closeFreeTrialDialog()" overflow /> -->
      <v-card>
        <v-card-text class="px-0 py-lg-15">
          <div
            class="d-flex flex-row flex-wrap justify-center align-center"
            style="overflow-y: scroll; max-height: 620px"
          >
            <div
              class="col-12 col-md-8 col-lg-8"
              style="position: relative; display: block; width: 100%"
            >
              <div
                class="box-shadow"
                style="
                  position: relative;
                  display: block;
                  width: 100%;
                  min-height: 320px;
                  max-height: 500px;
                  height: 100%;
                "
              >
                <iframe
                  src="https://player.vimeo.com/video/901759150?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                  title="Welcome Video"
                ></iframe>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4">
              <h1>Welcome to MEALZEE</h1>
              <br />
              <p>
                To start creating meal plans or recipe eBooks, activate your
                free 7-day trial in the subscription tab. Then enjoy access to
                MEALZEE Unlimited for the next 7 days. Check out the 'Support'
                page with a range of 'How-to' videos to get you started.
                Alternatively, book a personal demo with our team, by clicking
                the 'BOOK A DEMO' button on the sidebar.
              </p>
              <v-btn
                @click="dialog = false"
                :to="{ name: 'Subscription' }"
                color="primary"
                >Subscribe Here</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="dialog" width="1200" persistent eager>
      <close-button @click="closeFreeTrialDialog()" overflow />
      <v-card>
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/901759150?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="Welcome Video"
          ></iframe>
        </div>
      </v-card>
    </v-dialog> -->
  </v-app>
</template>

<script>
import firebase from 'firebase';
import { mapState, mapGetters } from 'vuex';
import TermsDialog from './views/auth/components/TermsDialog.vue';
import moment from 'moment';

export default {
  name: 'App',

  metaInfo() {
    return {
      title: this.isTempUrl ? '404' : 'Home',
      // titleTemplate: `%s | ${ this.isTempUrl ? 'Page not found' : (window.location.hostname == 'mealguide.pro' ? 'Meal Guide Pro' : process.env.VUE_APP_COMPANY_NAME) }`
    };
  },

  components: { TermsDialog },

  data: () => ({
    isTempUrl: true,
    redirected: false,
    subscribeDialog: false,
    AppTitle: process.env.VUE_APP_COMPANY_NAME,
    publicDrawer: false,
    snackbar: false,
    notification: '',
    videoDialog: false,
    expiredPopUpDialog: false,
    dialog: false,
    moment
  }),

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      error: (state) => state.errorMessage,
      success: (state) => state.successMessage,
    }),

    ...mapGetters('subscriptions', ['active']),

    plan: function () {
      return this.active;
    },

    drawer: function () {
      return this.$store.state.user.drawer;
    },
    mdUp: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },

  watch: {
    user: {
      handler(user) {
        if (user && user.role !== 'admin' && !user.agreeTermsAndConditions) {
          this.$refs.termsDialog.showDialog();
        }
      },
      immediate: true,
    },

    $route: {
      handler() {
        this.$store.commit('updateRouteName', this.$route.name);

        if (this.$route.name != 'Home' && this.$route.name != 'MealPlans' && this.$route.name != 'Recipes')
          this.dialog = false;
      },
      immediate: true,
    },
  },

  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit('user/setUser', null);
          this.$router.push({ name: 'Login' });
          this.$store.commit('mealplans/selectFolder', null);
          this.$store.commit('mealplans/setFolders', []);
        });
    },
    toggleDrawer() {
      this.$store.dispatch('user/toggleDrawer', !this.drawer);
    },

    // showFreeTrialDialog() {
    //   if (this.user?.freeTrialPopUp)
    //     this.dialog = true;
    //   else this.dialog = false;
    // },
    // closeFreeTrialDialog() {
    //   this.dialog = false;
    //   this.$store.dispatch('user/updateUserField', {
    //     field: 'freeTrialPopUp',
    //     value: false,
    //     silent: true,
    //   });
    // },

    checkFreeTrial() {
      // if (!this.user?.freeTrial && this.user?.expiredPopUp) {
      //   this.expiredPopUpDialog = true;
      // }

      if (this.user?.freeTrial) {
        let trialEnd = moment.unix(this.user?.trial_end.seconds);
        // console.log(trialEnd.format("dddd, MMMM Do YYYY, h:mm:ss a"));
        let diff = moment(trialEnd).diff(moment(), 'days');

        if (diff <= 0) {
          this.$store.dispatch('user/updateUserField', {
            field: 'freeTrial',
            value: false,
            silent: true,
          });
          this.$store.dispatch('user/updateUserField', {
            field: 'mealSlots',
            value: 0,
            silent: true,
          });
          this.$store.dispatch('user/updateUserField', {
            field: 'downloadCredits',
            value: 0,
            silent: true,
          });

          if (this.user.expiredPopUp) {
            this.expiredPopUpDialog = true;
          }
        }
      }
    },

    expiredDialog() {
      this.expiredPopUpDialog = false;
      this.$router.push({ name: 'Subscription' });
      this.$store.dispatch('user/updateUserField', {
        field: 'expiredPopUp',
        value: false,
        silent: true,
      });
    },
  },

  created() {
    if (
      ![
        'https://cq-nutrition.web.app',
        'https://cq-nutrition.firebaseapp.com',
      ].includes(window.location.origin)
    )
      this.isTempUrl = false;

    firebase.auth().onAuthStateChanged(() => {
      let user = firebase.auth().currentUser;

      if (user) {
        this.$store.dispatch('user/getUserData').then((doc) => {
          // GET AND SET THE TOKEN

          // this.showFreeTrialDialog();
          this.checkFreeTrial();
          if (this.$messaging !== null) {
            console.log('Firebase cloud messaging object', this.$messaging);

            this.$messaging
              .getToken({ vapidKey: process.env.VUE_VAPID_KEY })
              .then((currentToken) => {
                if (currentToken) {
                  // if (doc.data()?.tokenId !== currentToken && doc.data()?.role !== 'admin')  {

                  if (doc.data()?.tokenId !== currentToken) {
                    console.log('updated token');
                    this.$store.dispatch('user/updateUserField', {
                      field: 'tokenId',
                      value: currentToken,
                      silent: true,
                    });
                  }
                } else {
                  console.log(
                    'No registration token available. Request permission to generate one.'
                  );
                }
              })
              .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
              });
          }
        });

        this.$store.dispatch('subscriptions/getSubscriptions');

        this.$watch(
          () => [this.user, this.$route.name],
          () => {
            const today = moment();
            // CHECK IF USER IS STUDENT AND HAVE GRADUATED
            if (this.user?.isStudent) {
              const targetDate = moment(this.user.gradDate);

              if (!today.isBefore(targetDate)) {
                this.$store.dispatch('user/updateUserField', {
                  field: 'isStudent',
                  value: false,
                  silent: true,
                });
                this.$store.dispatch('user/updateUserField', {
                  field: 'mealSlots',
                  value: 0,
                  silent: true,
                });
                this.$store.dispatch('user/updateUserField', {
                  field: 'downloadCredits',
                  value: 0,
                  silent: true,
                });
              }
            }
            if (
              this.user &&
              [
                'dietitian',
                'personal_trainer',
                'nutritionist',
                'naturopath',
              ].includes(this.user.role) &&
              this.$route.name == 'Home'
            ) {
              this.$router.push({ name: 'MealPlans' });
            }
          },
          { immediate: true }
        );

        if (this.$messaging !== null) {
          this.$messaging.onMessage((payload) => {
            this.notification = payload.notification.body;
            this.snackbar = true;
          });
        }
      }
    });

    // GET APP SETTINGS
    this.$store.dispatch('settings/getSettings');

    this.$root.$on('showSubscribeDialog', () => {
      if (!this.user?.expiredPopUp) this.dialog = true;
    });

    // GET ORGANISATIONS
    this.$store.dispatch('organisation/getAllOrganisations');
  },
};
</script>

<style lang="scss">
.v-dialog {
  position: relative;
  overflow: visible !important;
}
</style>
