import Vue from 'vue';
import firebase from 'firebase';
import db from '@/firebase/init';
import { recipeIndex, myrecipeIndex } from '@/algolia/init';
import moment from 'moment';

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const initialState = () => {
  return {
    hits: [],
    errors: [],
    recipes: [],
    myRecipes: [],
    searched: [],
    pendings: [],
    requested: [],
    allRecipes: [],
    declined: [],
    nonExistent: [],
    totalValues: [],
    allRecipeIds: [],
    savedRecipes: [],
    lastVisible: null,
    recipesByDietitian: [],
    recipeIngredientIds: [],
    currentServes: 0,
    status: {
      limit: 0,
      getting: false,
      deleting: false,
      routeName: null,
      firstLoad: false,
      searching: false,
      approving: false,
      loadingMore: false,
      gettingAllIds: false,
      firstLoadErrors: false,
      lastPendingVisible: null,
      lastDeclinedVisible: null,
      lastRequestedVisible: null,
      gettingPendingRecipes: false,
      gettingRecipesByDietitian: false,
      lastRecipesByDietitianVisible: null,
    },
  };
};

const state = initialState();

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  recipe: (state) => (id) => {
    if (state.recipes) {
      let recipe;
      recipe = state.recipes.find((r) => r.id == id);

      if (!recipe) recipe = state.myRecipes.find((r) => r.id == id);

      return recipe || {};
    }
    return {};
  },
  totalRecipes: (state) => {
    return state.allRecipeIds.length;
  },
};

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },

  setRecipes(state, payload) {
    payload.forEach((doc) => {
      let data = Vue.prototype.$formatData(doc);
      if (!state.recipes.find((r) => r.id == data.id)) data.isPrivate = false;
      state.recipes.push(data);
    });

    state.status.getting = false;
    state.status.firstLoad = true;
  },

  setAllRecipes(state, payload) {
    payload.forEach((doc) => {
      let data = Vue.prototype.$formatData(doc);
      if (!state.allRecipes.find((r) => r.id == data.id)) data.isPrivate = false;
      state.allRecipes.push(data);
    });

    state.status.getting = false;
    state.status.firstLoad = true;
  },

  setMyRecipes(state, payload) {
    payload.forEach((doc) => {
      let data = Vue.prototype.$formatData(doc);
      if (!state.recipes.find((r) => r.id == data.id)) data.isPrivate = true;
      state.myRecipes.push(data);
    });

    state.status.getting = false;
    state.status.firstLoad = true;
  },

  emptyRecipes(state) {
    state.recipes = [];
  },

  insertRecipe(state, payload) {
    let data = Vue.prototype.$formatData(payload);
    data.privateRecipe = false;
    state.recipes.push(data);
  },

  insertMyRecipe(state, payload) {
    let data = Vue.prototype.$formatData(payload);
    data.privateRecipe = true;
    state.myRecipes.push(data);
  },

  insertNonExistent(state, id) {
    state.nonExistent.push(id);
  },

  setDeletingState(state, bol) {
    state.status.deleting = bol;
  },

  removeRecipe(state, recipe) {
    console.log(recipe);
    if (recipe.status == 'published')
      state.recipes.splice(state.recipes.indexOf(recipe), 1);
    else if (recipe.status == 'pending')
      state.pendings = state.pendings.filter((rec) => rec.id !== recipe.id);
    else if (recipe.status == 'declined')
      state.declined = state.declined.filter((rec) => rec.id !== recipe.id);
    else if (recipe.status == 'requested')
      state.requested = state.requested.filter((rec) => rec.id !== recipe.id);
  },

  removeMyRecipe(state, recipe) {
    state.myRecipes.splice(state.myRecipes.indexOf(recipe), 1);
  },

  setSearchingState(state, bol) {
    state.status.searching = bol;
  },

  setSearchResults(state, payload) {
    state.searched = payload;
    state.status.searching = false;
  },

  clearSearch(state) {
    state.hits = [];
  },

  clearRecipesByDietitian(state) {
    state.recipesByDietitian = [];
  },

  setLastVisible(state, recipe) {
    state.lastVisible = recipe;
  },

  setLoadingMoreState(state, bol) {
    state.status.loadingMore = bol;
  },

  addRecipeIngredientId(state, payload) {
    if (!state.recipeIngredientIds.find((r) => r.recipe == payload.recipe)) {
      state.recipeIngredientIds.push(payload);
    }
  },

  setPendings(state, payload) {
    if (payload.size) {
      payload.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.ref = doc.ref;

        if (!state.pendings.find((p) => p.id == data.id))
          state.pendings.push(data);
      });
    }
  },
  setRequested(state, payload) {
    if (payload.size) {
      payload.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.ref = doc.ref;

        if (!state.requested.find((p) => p.id == data.id))
          state.requested.push(data);
      });
    }
  },

  setDeclined(state, payload) {
    if (payload.size) {
      payload.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.ref = doc.ref;

        if (!state.declined.find((p) => p.id == data.id))
          state.declined.push(data);
      });
    }
  },

  setRecipesByDietitian(state, payload) {
    if (payload.size) {
      payload.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.ref = doc.ref;

        if (!state.recipesByDietitian.find((p) => p.id == data.id))
          state.recipesByDietitian.push(data);
      });
    }
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0];
  },

  pendingToPublished(state, payload) {
    let recipe = state.pendings.find((r) => r.id == payload.id);
    state.pendings.splice(state.pendings.indexOf(recipe), 1);
    recipe.status = 'published';

    if (payload?.isCustomized) recipe.isCustomized = false;

    state.recipes.push(recipe);
  },

  publishedToPending(state, payload) {
    let recipe = state.recipes.find((r) => r.id == payload.id);
    state.recipes.splice(state.recipes.indexOf(recipe), 1);
    recipe.status = 'pending';
    state.pendings.push(recipe);
  },

  requestedToPending(state, payload) {
    let recipe = state.requested.find((r) => r.id == payload.id);
    state.requested.splice(state.requested.indexOf(recipe), 1);
    recipe.status = 'pending';

    if (payload?.isCustomized) recipe.isCustomized = false;

    state.pendings.push(recipe);
  },

  requestedToDeclined(state, payload) {
    let recipe = state.requested.find((r) => r.id == payload.id);
    state.requested.splice(state.requested.indexOf(recipe), 1);
    recipe.status = 'declined';
    state.declined.push(recipe);
  },

  declinedToPublished(state, payload) {
    let recipe = state.declined.find((r) => r.id == payload.id);
    state.declined.splice(state.declined.indexOf(recipe), 1);
    recipe.status = 'published';

    if (payload?.isCustomized) recipe.isCustomized = false;

    state.recipes.push(recipe);
  },

  setTotalValues(state, payload) {
    let values = state.totalValues.find(
      (t) => t.day == payload.day && t.meal == payload.meal
    );

    if (values) {
      Vue.set(state.totalValues, state.totalValues.indexOf(values), payload);
    } else state.totalValues.push(payload);
  },

  removeValue(state, payload) {
    let value = state.totalValues.find(
      (v) => v.day == payload.day && v.meal == payload.meal
    );

    state.totalValues.splice(state.totalValues.indexOf(value), 1);
  },

  updateRecipeField(state, payload) {
    console.log(payload);
    Vue.set(payload.recipe, payload.field, payload.value);
  },

  setHits(state, payload) {
    state.hits = [];

    if (payload.length) {
      payload.forEach((doc) => {
        if (doc.exists) {
          let recipe = doc.data();
          recipe.id = doc.id;
          recipe.ref = doc.ref;

          if (!state.hits.find((h) => h.id == doc.id)) state.hits.push(recipe);
        }
      });
    }
  },

  setErrors(state, payload) {
    state.errors = [];

    if (payload.size) {
      payload.forEach((doc) => {
        let error = doc.data();
        error.id = doc.id;
        error.ref = doc.ref;

        if (!state.errors.find((e) => e.id == error.id))
          state.errors.push(error);
      });
    }
  },

  setServesByMeal(state, payload) {
    let data = Vue.prototype.$formatData(payload);
    state.currentMealServes = data.serves;
  },

  setRecipeIds(state, payload) {
    state.allRecipeIds = payload;
  },

  setGettingAllRecipeIds(state, payload) {
    state.status.gettingAllIds = payload;
  },

  insertSavedRecipe(state, payload) {
    let data = Vue.prototype.$formatData(payload);
    data.privateRecipe = false;
    state.savedRecipes.push(data);
  },
  insertSavedRecipe2(state, payload) {
    state.savedRecipes.push(payload);
  },
  removeSavedRecipe(state, id) {
    state.savedRecipes = state.savedRecipes.filter((r) => r.id !== id);
  }
};

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {
  /*------------------------------------------------------------------------------
   * GET RECIPES
   *----------------------------------------------------------------------------*/
  async getRecipes({ state, commit, dispatch, rootState }, meal = null) {
    commit('updateStatus', { firstLoad: true });
    if (!state.lastVisible) commit('updateStatus', { getting: true });
    else commit('setLoadingMoreState', true);
    let user = rootState.user.user;

    let query = db
      .collection('recipes')
      .where('status', '==', 'published')
      .orderBy('updatedAt', 'desc');

    // IF USER IS DEITITIAN OR PERSONAL TRAINER
    if (
      ['dietitian', 'personal_trainer', 'nutritionist', 'naturopath'].includes(user.role) &&
      rootState.routeName !== 'MealPlans' && rootState.routeName !== 'RecipePlans'
    )
      query = query.where('user', '==', user.id);

    if (meal) {
      if (meal.includes('Snack')) meal = 'Snack';
      query = query.where('mealTime', 'array-contains', meal);
    }

    if (state.lastVisible) query = query.startAfter(state.lastVisible);
    if (state.status.limit) query = query.limit(state.status.limit);

    await query
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          commit('setLastVisible', snapshot.docs[snapshot.docs.length - 1]);
          commit('setRecipes', snapshot);
          commit('setLoadingMoreState', false);

          // GET INGREDIENTS
          snapshot.forEach((doc) => {
            dispatch('members/getMember', doc.data().user, { root: true });
          });
        } else {
          if (state.recipes.length) {
            dispatch('showError', 'No more recipes found', { root: true });
          } else {
            commit('emptyRecipes');
          }
          commit('updateStatus', { getting: false });
          commit('setLoadingMoreState', false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        dispatch('showError', error.message, { root: true });
        commit('updateStatus', { getting: false });
        commit('setLoadingMoreState', false);
      });
  },

  /*------------------------------------------------------------------------------
   * GET ALL RECIPES
   *----------------------------------------------------------------------------*/
  async getAllRecipes({ state, commit, dispatch }, meal = null) {
    commit('updateStatus', { firstLoad: true });
    if (!state.lastVisible) commit('updateStatus', { getting: true });
    else commit('setLoadingMoreState', true);

    let query = db
      .collection('recipes')
      .where('status', '==', 'published')
      .orderBy('updatedAt', 'desc');

    if (meal) {
      if (meal.includes('Snack')) meal = 'Snack';
      query = query.where('mealTime', 'array-contains', meal);
    }

    if (state.lastVisible) query = query.startAfter(state.lastVisible);

    // if (state.status.limit) query = query.limit(state.status.limit);

    await query
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          commit('setLastVisible', snapshot.docs[snapshot.docs.length - 1]);
          commit('setAllRecipes', snapshot);
          commit('setLoadingMoreState', false);

          // GET INGREDIENTS
          snapshot.forEach((doc) => {
            dispatch('members/getMember', doc.data().user, { root: true });
          });
        } else {
          if (state.recipes.length) {
            dispatch('showError', 'No more recipes found', { root: true });
          } else {
            commit('emptyRecipes');
          }
          commit('updateStatus', { getting: false });
          commit('setLoadingMoreState', false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        dispatch('showError', error.message, { root: true });
        commit('updateStatus', { getting: false });
        commit('setLoadingMoreState', false);
      });
  },

  /*------------------------------------------------------------------------------
   * GET RECIPES
   *----------------------------------------------------------------------------*/
  async getMyRecipes({ state, commit, dispatch, rootState }, meal = null) {
    commit('updateStatus', { firstLoad: true });
    if (!state.lastVisible) commit('updateStatus', { getting: true });
    else commit('setLoadingMoreState', true);
    let user = rootState.user.user;

    let query = db.collection('my_recipes').orderBy('name', 'asc');

    // IF USER IS DEITITIAN OR PERSONAL TRAINER
    if (['dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath'].includes(user.role))
      query = query.where('user', '==', user.id);

    if (meal) {
      if (meal.includes('Snack')) meal = 'Snack';
      query = query.where('mealTime', 'array-contains', meal);
    }

    if (state.lastVisible) query = query.startAfter(state.lastVisible);

    // if (state.status.limit) query = query.limit(state.status.limit);

    await query
      .get()
      .then((snapshot) => {
        if (snapshot.size) {
          commit('setLastVisible', snapshot.docs[snapshot.docs.length - 1]);
          commit('setMyRecipes', snapshot);
          snapshot.forEach((doc) => {
            dispatch('members/getMember', doc.data().user, { root: true });
          });

          if (!['dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath'].includes(user.role))
            commit('setLoadingMoreState', false);

          commit('updateStatus', { getting: false });
        } else {
          if (!['dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath'].includes(user.role))
            commit('setLoadingMoreState', false);

          commit('updateStatus', { getting: false });
        }

        if (['dietitian', 'personal_trainer', 'member', 'nutritionist', 'naturopath'].includes(user.role)) {
          db.collection('recipes')
            .orderBy('name', 'asc')
            .where('customized_by_id', '==', user.id)
            .get()
            .then((snapshot) => {
              if (snapshot.size) {
                commit('setMyRecipes', snapshot);
                snapshot.forEach((doc) => {
                  dispatch('members/getMember', doc.data().user, {
                    root: true,
                  });
                });
              }
            });
        }
      })
      .catch((error) => {
        console.log(error.message);
        dispatch('showError', error.message, { root: true });
        commit('updateStatus', { getting: false });
        commit('setLoadingMoreState', false);
      });
  },

  /*------------------------------------------------------------------------------
   * GET RECIPE FOR EBOOK
   *----------------------------------------------------------------------------*/
  async getRecipeEbook({ dispatch }, data) {
    var id = data.id;

    return await db
      .collection('recipes')
      .doc(id)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          let recipeData = Vue.prototype.$formatData(doc);

          if (data.ingredients) dispatch('getRecipeIngredients', doc);
          dispatch('members/getMember', doc.data().user, { root: true });

          return (recipeData);
        } else {
          return await db
            .collection('my_recipes')
            .doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                let myRecipeData = Vue.prototype.$formatData(doc);

                if (data.ingredients) dispatch('getRecipeIngredients', doc);
                dispatch('members/getMember', doc.data().user, {
                  root: true,
                });

                return myRecipeData
              } else {
                return ('insertNonExistent', id);
              }
            });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

  },

  /*------------------------------------------------------------------------------
   * GET RECIPE
   *----------------------------------------------------------------------------*/
  async getRecipe({ commit, state, dispatch }, data) {
    var error = null;
    var id = data.id;
    let recipe = state.recipes.find((recipe) => recipe.id == id);

    if (!recipe) {
      await db
        .collection('recipes')
        .doc(id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            commit('insertRecipe', doc);
            if (data.ingredients) dispatch('getRecipeIngredients', doc);
            dispatch('members/getMember', doc.data().user, { root: true });
          } else {
            await db
              .collection('my_recipes')
              .doc(id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  commit('insertMyRecipe', doc);
                  if (data.ingredients) dispatch('getRecipeIngredients', doc);
                  dispatch('members/getMember', doc.data().user, {
                    root: true,
                  });
                } else {
                  commit('insertNonExistent', id);
                  error = `Recipe ${id} does not exist`;
                }
              });
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    return error;
  },
  /*------------------------------------------------------------------------------
   * GET RECIPE
   *----------------------------------------------------------------------------*/
  async getSavedRecipes({ commit, dispatch }, user) {

    // check if has a saved recipe
    if (!user.savedRecipes && !user?.savedRecipes.length) return

    user.savedRecipes.forEach(async (id) => {
      await db
        .collection('recipes')
        .doc(id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            commit('insertSavedRecipe', doc);
            dispatch('members/getMember', doc.data().user, { root: true });
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    })



  },


  /*------------------------------------------------------------------------------
   * GET MY RECIPE
   *----------------------------------------------------------------------------*/
  async getMyRecipe({ commit, state, dispatch }, data) {
    var error = null;
    var id = data.id;
    let recipe = state.myRecipes.find((recipe) => recipe.id == id);

    if (!recipe) {
      await db
        .collection('my_recipes')
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            commit('insertMyRecipe', doc);
            if (data.ingredients) dispatch('getRecipeIngredients', doc);
          } else {
            commit('insertNonExistent', id);
            error = `Recipe ${id} does not exist`;
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

    return error;
  },

  /*------------------------------------------------------------------------------
   * GET RECIPE INGREDIENTS
   *----------------------------------------------------------------------------*/
  getRecipeIngredients({ commit, dispatch }, recipe) {
    if (recipe.ref)
      recipe.ref
        .collection('ingredients')
        .get()
        .then((snapshot) => {
          if (snapshot.size) {
            var datas = [];

            snapshot.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              data.ref = doc.ref;
              datas.push(data);
              dispatch(
                'ingredients/getFoods',
                datas.map((d) => d.food),
                { root: true }
              );
            });

            commit('addRecipeIngredientId', {
              recipe: recipe.id,
              ids: datas,
            });
          }
        })
        .catch((error) => {
          console.log(error.message);
          dispatch('showError', error.message, { root: true });
        });
  },

  /*------------------------------------------------------------------------------
   * DELETE RECIPE
   *----------------------------------------------------------------------------*/
  async deleteRecipe({ commit, dispatch }, recipe) {
    commit('setDeletingState', true);

    await recipe.ref
      .delete()
      .then(async () => {
        if (recipe.photo) {
          var storageRef = firebase.storage().ref();
          try {
            await storageRef.child(`recipes/${recipe.photo}`).delete();
            await storageRef.child(`recipes/thumb_${recipe.photo}`).delete();
            await storageRef.child(`recipes/medium_${recipe.photo}`).delete();
            await storageRef.child(`recipes/large_${recipe.photo}`).delete();
          } catch (e) {
            console.log(e);
          }
        }

        // DELETE FROM ALGOLIA
        await recipeIndex.deleteObject(recipe.id);
        commit('removeRecipe', recipe);
        commit('setDeletingState', false);
        dispatch('showSuccess', 'Recipe successfully deleted.', { root: true });
      })
      .catch((error) => {
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * DECLINE CUSTOM RECIPE
   *----------------------------------------------------------------------------*/
  async declineRecipe({ commit, dispatch }, recipe) {
    commit('updateStatus', { deleting: true });

    return new Promise((res, rej) => {
      recipe.ref
        .update({ status: 'declined' })
        .then(() => {
          commit('updateStatus', { deleting: false });
          commit('requestedToDeclined', recipe);

          // let data = {
          //   recipient: recipe.customized_by_email,
          //   subject: 'Request Declined',
          //   message: `Hi ${recipe.customized_by_name},<br/> Your request to customize the recipe "<strong>${recipe.name}</strong>" has been declined by the Dietitian.`,
          // };

          // dispatch('mail/notify', data, { root: true });
          dispatch('showSuccess', 'Request has been Declined', { root: true });

          res();
        })
        .catch((error) => {
          console.log(error.message);
          commit('updateStatus', { approving: false });
          rej();
        });
    });
  },

  /*------------------------------------------------------------------------------
   * DELETE CUSTOM RECIPE
   *----------------------------------------------------------------------------*/
  async deleteDeclinedRecipe({ commit, dispatch }, recipe) {
    commit('setDeletingState', true);

    return new Promise((res, rej) => {
      recipe.ref
        .delete()
        .then(async () => {
          if (recipe.photo) {
            var storageRef = firebase.storage().ref();
            try {
              await storageRef.child(`recipes/${recipe.photo}`).delete();
              await storageRef.child(`recipes/thumb_${recipe.photo}`).delete();
              await storageRef.child(`recipes/medium_${recipe.photo}`).delete();
              await storageRef.child(`recipes/large_${recipe.photo}`).delete();
            } catch (e) {
              console.log(e);
            }
          }

          // DELETE FROM ALGOLIA
          await recipeIndex.deleteObject(recipe.id);
          commit('removeRecipe', recipe);
          commit('setDeletingState', false);

          dispatch('showSuccess', 'Custom Recipe deleted', { root: true });
          res();
        })
        .catch((error) => {
          dispatch('showError', error.message, { root: true });
          rej();
        });
    });
  },

  /*------------------------------------------------------------------------------
   * DELETE MY RECIPE
   *----------------------------------------------------------------------------*/
  async deleteMyRecipe({ commit, dispatch }, recipe) {
    commit('setDeletingState', true);

    await recipe.ref
      .delete()
      .then(async () => {
        if (recipe.photo) {
          var storageRef = firebase.storage().ref();
          try {
            await storageRef.child(`recipes/${recipe.photo}`).delete();
            await storageRef.child(`recipes/thumb_${recipe.photo}`).delete();
            await storageRef.child(`recipes/medium_${recipe.photo}`).delete();
            await storageRef.child(`recipes/large_${recipe.photo}`).delete();
          } catch (e) {
            console.log(e);
          }
        }

        // DELETE FROM ALGOLIA
        await myrecipeIndex.deleteObject(recipe.id);
        commit('removeMyRecipe', recipe);
        commit('setDeletingState', false);
        dispatch('showSuccess', 'Recipe successfully deleted.', { root: true });
      })
      .catch((error) => {
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * SEARCH RECIPES
   *----------------------------------------------------------------------------*/
  searchRecipes({ commit, dispatch, }, term) {
    // let user = rootState.user.user;
    commit('setSearchingState', true);

    recipeIndex
      .search(term, {
        attributesToRetrieve: ['name', 'objectID'],
      })
      .then(({ hits }) => {
        if (hits.length) {
          let promises = [];

          hits.forEach((hit) => {
            promises.push(db.collection('recipes').doc(hit.objectID).get());
          });

          Promise.all(promises).then((docs) => {
            commit('setSearchingState', false);
            commit('setHits', docs);

            // if(user.role == 'admin')
            //   commit('setHits', docs);
            // else {

            //   let allDocs = docs.filter(doc => doc.data()?.user === user?.id)

            //   if(!allDocs.length) 
            //     dispatch('showError', 'No recipes found.', { root: true });

            //   else
            //     commit('setHits', allDocs);

            // }
          });
        } else {
          commit('setSearchingState', false);
          dispatch('showError', 'No recipes found.', { root: true });
        }
      });
  },

  /*------------------------------------------------------------------------------
   * SEARCH RECIPES
   *----------------------------------------------------------------------------*/
  searchMyRecipes({ commit, dispatch, rootState }, term) {
    let user = rootState.user.user;
    commit('setSearchingState', true);

    myrecipeIndex
      .search(term, {
        attributesToRetrieve: ['name', 'objectID', 'user'],
      })
      .then(({ hits }) => {
        if (hits.length) {
          let promises = [];

          if (user.role != 'admin')
            hits = hits.filter((h) => h.user === user.id);

          hits.forEach((hit) => {
            promises.push(db.collection('my_recipes').doc(hit.objectID).get());
          });

          Promise.all(promises).then((docs) => {
            commit('setSearchingState', false);
            commit('setHits', docs);
          });
        } else {
          commit('setSearchingState', false);
          dispatch('showError', 'No recipes found.', { root: true });
        }
      });
  },

  /*------------------------------------------------------------------------------
   * GET PENDING RECIPES
   *----------------------------------------------------------------------------*/
  getPendingRecipes({ state, commit, dispatch, rootState }) {
    let user = rootState.user.user;
    commit('updateStatus', { loadingMore: true });

    let task = db
      .collection('recipes')
      .where('status', '==', 'pending')
      .orderBy('createdAt', 'desc');

    if (user && user.role !== 'admin') {
      task = task.where('user', '==', user.id);
    }

    if (state.status.lastPendingVisible) {
      task = task.startAfter(state.status.lastPendingVisible);
    }

    task
      .get()
      .then((snapshot) => {
        commit('setPendings', snapshot);

        if (snapshot.size) {
          commit('updateStatus', { loadingMore: false });
          commit('updateStatus', {
            lastPendingVisible: snapshot.docs[snapshot.docs.length - 1],
          });

          snapshot.forEach((doc) => {
            dispatch('members/getMember', doc.data().user, { root: true });
          });
        } else commit('updateStatus', { loadingMore: false });
      })
      .catch((error) => {
        console.log(error.message);
        commit('updateStatus', { loadingMore: false });
      });
  },

  /*------------------------------------------------------------------------------
   * GET REQUESTED RECIPES
   *----------------------------------------------------------------------------*/
  getRequestedRecipes({ state, commit, dispatch, rootState }) {
    let user = rootState.user.user;
    commit('updateStatus', { loadingMore: true });

    let task = db
      .collection('recipes')
      .where('status', '==', 'requested')
      .orderBy('createdAt', 'desc');

    if (user && user.role !== 'admin') {
      task = task.where('user', '==', user.id);
    }

    if (state.status.lastRequestedVisible) {
      task = task.startAfter(state.status.lastRequestedVisible);
    }

    task
      .get()
      .then((snapshot) => {
        commit('setRequested', snapshot);

        if (snapshot.size) {
          commit('updateStatus', { loadingMore: false });
          commit('updateStatus', {
            lastRequestedVisible: snapshot.docs[snapshot.docs.length - 1],
          });

          snapshot.forEach((doc) => {
            dispatch('members/getMember', doc.data().user, { root: true });
          });
        } else commit('updateStatus', { loadingMore: false });
      })
      .catch((error) => {
        console.log(error.message);
        commit('updateStatus', { loadingMore: false });
      });
  },

  /*------------------------------------------------------------------------------
   * GET DECLINED RECIPES
   *----------------------------------------------------------------------------*/
  getDeclinedRecipes({ state, commit, dispatch, rootState }) {
    let user = rootState.user.user;
    commit('updateStatus', { loadingMore: true });

    let task = db
      .collection('recipes')
      .where('status', '==', 'declined')
      .orderBy('createdAt', 'desc');

    if (user && user.role !== 'admin') {
      task = task.where('user', '==', user.id);
    }

    if (state.status.lastDeclinedVisible) {
      task = task.startAfter(state.status.lastDeclinedVisible);
    }

    task
      .get()
      .then((snapshot) => {
        commit('setDeclined', snapshot);

        if (snapshot.size) {
          commit('updateStatus', { loadingMore: false });
          commit('updateStatus', {
            lastDeclinedVisible: snapshot.docs[snapshot.docs.length - 1],
          });

          snapshot.forEach((doc) => {
            dispatch('members/getMember', doc.data().user, { root: true });
          });
        } else commit('updateStatus', { loadingMore: false });
      })
      .catch((error) => {
        console.log(error.message);
        commit('updateStatus', { loadingMore: false });
      });
  },

  /*------------------------------------------------------------------------------
   * GET RECIPES BY DIETITIAN
   *----------------------------------------------------------------------------*/
  getRecipesByDietitian({ state, commit }, id) {
    commit('updateStatus', { loadingMore: true });

    if (!state.recipesByDietitian.length)
      commit('updateStatus', { gettingRecipesByDietitian: true })


    let task = db
      .collection('recipes')
      .where('user', '==', id)
      .orderBy('createdAt', 'desc');

    if (state.status.lastRecipesByDietitianVisible) {
      task = task.startAfter(state.status.lastRecipesByDietitianVisible);
    }

    task
      .get()
      .then((snapshot) => {
        commit('setRecipesByDietitian', snapshot);

        if (snapshot.size) {
          commit('updateStatus', { loadingMore: false });
          commit('updateStatus', {
            lastRecipesByDietitianVisible: snapshot.docs[snapshot.docs.length - 1],
          });
          commit('updateStatus', { gettingRecipesByDietitian: false })

        } else {
          commit('updateStatus', { loadingMore: false });
          commit('updateStatus', { gettingRecipesByDietitian: false })
        }
      })
      .catch((error) => {
        console.log(error.message);
        commit('updateStatus', { loadingMore: false });
        commit('updateStatus', { gettingRecipesByDietitian: false })
      });
  },


  /*------------------------------------------------------------------------------
   * APPROVE RECIPE
   *----------------------------------------------------------------------------*/
  async approveRecipe({ commit, dispatch }, recipe) {
    commit('updateStatus', { approving: true });

    let updatedAt = firebase.firestore.Timestamp.now();

    updatedAt = moment(updatedAt.toDate()).format('');

    if (recipe) {
      await recipe.ref
        .update({ status: 'published' })
        .then(() => {
          commit('updateStatus', { approving: false, updatedAt });

          commit('pendingToPublished', recipe);

          // Get the user who created
          // the food
          db.collection('users')
            .doc(recipe.user)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const { role, email } = doc.data();
                // Send an email if he/she is a Dietitian/Nutritionist/Naturopath
                if (role === 'dietitian' || role === 'nutritionist' || role === 'naturopath') {
                  let data = {
                    recipient: email,
                    subject: 'Recipe Approved',
                    message: `Your recipe "<strong>${recipe.name}</strong>" has been approved!`,
                  };

                  dispatch('mail/notify', data, { root: true });
                }
              }
            })
            .catch((error) => {
              console.log(error.message);
              dispatch('showError', error.message, { root: true });
            });

          dispatch('showSuccess', 'Recipe status updated to published', {
            root: true,
          });
        })
        .catch((error) => {
          console.log(error.message);
          commit('updateStatus', { approving: false });
        });
    }
  },

  /*------------------------------------------------------------------------------
   * APPROVE DECLINED RECIPE
   *----------------------------------------------------------------------------*/
  async approveDeclinedRecipe({ commit, dispatch, rootState }, recipe) {
    commit('updateStatus', { approving: true });

    let updatedAt = firebase.firestore.Timestamp.now();

    updatedAt = moment(updatedAt.toDate()).format('');

    let user = rootState.user.user;
    if (recipe) {
      await recipe.ref
        .update({
          status: 'published',
          user: user.id,
          isCustomized: false,
          updatedAt,
        })
        .then(() => {
          commit('updateStatus', { approving: false });

          commit('declinedToPublished', recipe);

          dispatch('showSuccess', 'Recipe status updated to published', {
            root: true,
          });
        })
        .catch((error) => {
          console.log(error.message);
          commit('updateStatus', { approving: false });
        });
    }
  },

  /*------------------------------------------------------------------------------
   * APPROVE CUSTOM RECIPE
   *----------------------------------------------------------------------------*/
  async approveCustomRecipe({ commit, dispatch }, recipe) {
    commit('updateStatus', { approving: true });

    let updatedAt = firebase.firestore.Timestamp.now();
    updatedAt = moment(updatedAt.toDate()).format('');

    return new Promise((res, rej) => {
      recipe.ref
        .update({ status: 'pending', isCustomized: false, updatedAt })
        .then(() => {
          commit('updateStatus', { approving: false });
          commit('requestedToPending', recipe);

          // let data = {
          //   recipient: recipe.customized_by_email,
          //   subject: 'Request Approved',
          //   message: `Hi ${recipe.customized_by_name},<br/> Your request to customize the recipe "<strong>${recipe.name}</strong>" has been approved by the Dietitian! <br/> It's now being review by the admin`,
          // };

          // dispatch('mail/notify', data, { root: true });
          dispatch(
            'showSuccess',
            'Recipe status updated to pending. Thank you',
            { root: true }
          );

          res();
        })
        .catch((error) => {
          console.log(error.message);
          commit('updateStatus', { approving: false });
          rej();
        });
    });
  },

  /*------------------------------------------------------------------------------
   * ACCEPT CUSTOM RECIPE
   *----------------------------------------------------------------------------*/
  async acceptDeclinedRecipe({ commit, dispatch }, recipe) {
    commit('updateStatus', { approving: true });

    let updatedAt = firebase.firestore.Timestamp.now();
    updatedAt = moment(updatedAt.toDate()).format('');

    return new Promise((res, rej) => {
      recipe.ref
        .update({ status: 'published', isCustomized: false, updatedAt })
        .then(() => {
          commit('updateStatus', { approving: false });
          commit('declinedToPublished', recipe);

          dispatch('showSuccess', 'Recipe status updated to published.', {
            root: true,
          });

          res();
        })
        .catch((error) => {
          console.log(error.message);
          commit('updateStatus', { approving: false });
          rej();
        });
    });
  },

  /*------------------------------------------------------------------------------
   * UPDATE TO PENDING
   *----------------------------------------------------------------------------*/
  async updateToPending({ commit, dispatch }, recipe) {

    let updatedAt = firebase.firestore.Timestamp.now();
    updatedAt = moment(updatedAt.toDate()).format('');

    await recipe.ref
      .update({ status: 'pending', updatedAt })
      .then(() => {
        dispatch('showSuccess', 'Status moved to pending', { root: true });
        commit('publishedToPending', recipe);
      })
      .catch((error) => {
        console.log(error.message);
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * UPDATE RECIPE FIELD
   *
   * @params
   *  Object
   *    recipe: Object
   *    field: String
   *    value: Boolean
   *    silent: Boolean (optional)
   *    message: String (optional)
   *----------------------------------------------------------------------------*/
  async updateRecipeField({ commit, dispatch }, data) {
    await data.recipe.ref
      .update({
        [data.field]: data.value,
        updated: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        commit('updateRecipeField', data);
        if (!data.silent)
          dispatch('showSuccess', data.message || 'Recipe updated', {
            root: true,
          });
      })
      .catch((error) => {
        console.log(error.message);
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * GET ERROR RECIPES
   *----------------------------------------------------------------------------*/
  getErrorRecipes({ commit }) {
    commit('updateStatus', { firstLoadErrors: true });

    db.collection('recipes')
      .where('status', '==', 'error')
      .get()
      .then((snapshot) => {
        commit('setErrors', snapshot);
      })
      .catch((error) => {
        console.log(error.message);
      });
  },
  /*------------------------------------------------------------------------------
   * GET SERVES BY MEALS
   *----------------------------------------------------------------------------*/

  async getServesByMeal({ commit }, { planId, mealId }) {
    await db
      .collection('mealPlans')
      .doc(planId)
      .collection('meals')
      .doc(mealId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let payload = doc;
          let data = Vue.prototype.$formatData(payload);

          console.log(data);

          if (data?.mealPrep) {
            return db
              .collection('mealPlans')
              .doc(planId)
              .collection('meals')
              .doc(data.originData.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  commit('setServesByMeal', doc);
                }
              });
          }

          commit('setServesByMeal', doc);
        }
        console.log(doc);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  /*------------------------------------------------------------------------------
   * GET ALL RECIPE IDs
   *----------------------------------------------------------------------------*/

  async getAllIds({ commit }) {
    commit('setGettingAllRecipeIds', true);

    await db
      .collection('recipes')
      .where('status', '==', 'published')
      .orderBy('updatedAt', 'desc')
      .get()
      .then((snapshot) => {
        let docs = snapshot.docs;

        const recipeIds = docs.map((doc) => {
          return {
            ...doc.data(),
            ref: doc.ref,
            id: doc.id,
          };
        });

        commit('setRecipeIds', recipeIds);

        commit('setGettingAllRecipeIds', false);
      });
  },

  removeValueAction({ commit }, { meal, day }) {
    commit('removeValue', { meal, day });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
