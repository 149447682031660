import db from '@/firebase/init'
import firebase from 'firebase'
import { omit, omitBy, isUndefined } from 'lodash'
import Vue from 'vue'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  foods: [],
  reports: [],
  categories: [],
  hiddenFoods: [],
  pendingFoods: [],
  foodNames: [],
  status: {
    saving: false,
    adding: false,
    approving: [],
    getting: false,
    deleting: false,
    updating: false,
    resolving: null,
    firstLoad: false,
    reporting: false,
    bookmarked: null,
    lastVisible: null,
    updatingDefault: false,
    addingCategory: false,
    deletingCategory: false,
    firstPendingLoad: false,
    firstHiddenLoad: false,
    reportsFirstLoad: false,
    lastPendingVisible: null,
    lastHiddenVisible: null,
    gettingCategories: false,
    gettingHiddenFoods: false,
    categoriesFirstLoad: false,
    gettingPendingFoods: false,
  }
}

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  categoryData: (state) => (id) => {
    if (state.categories) {
      let category = state.categories.find(c => c.id == id)
      return category || {}
    }
  },

  reports: (state) => (food) => {
    if (state.reports) {
      return state.reports.filter(r => r.food == food)
    }
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0]
  },

  addFoods(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        let food = doc.data()
        food.id = doc.id
        food.ref = doc.ref
        if (!state.foods.find(f => f.id == food.id))
          state.foods.push(food)
      })

      state.status.lastVisible = payload.docs[payload.docs.length - 1]
    }

    state.status.getting = false
  },

  addFoodNames(state, payload) {
    if(payload.size) {
      payload.forEach(doc => {
        let food = doc.data()

        state.foodNames.push(food.name)
      })
    }
  },

  addFood(state, payload) {
    let food = state.foods.find(f => f.id == payload.id)
    if (food) Vue.set(state.foods, state.foods.indexOf(food), payload)
    else state.foods.push(payload)
  },
  
  addPendingFoods(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        let food = doc.data()
        food.id = doc.id
        food.ref = doc.ref
        if (!state.pendingFoods.find(f => f.id == food.id))
          state.pendingFoods.push(food)
      })

      state.status.lastPendingVisible = payload.docs[payload.docs.length - 1]
    }

    state.status.gettingPendingFoods = false
  },
  
  setHiddenFoods(state, payload) {
    if (payload.size) {
      payload.forEach(doc => {
        let food = doc.data()
        food.id = doc.id
        food.ref = doc.ref
        if (!state.hiddenFoods.find(f => f.id == food.id))
          state.hiddenFoods.push(food)
      })

      state.status.lastHiddenVisible = payload.docs[payload.docs.length - 1]
    }

    state.status.gettingHiddenFoods = false
  },

  removeFromPending(state, payload) {
    let food = state.pendingFoods.find(f => f.id == payload.id)
    if (food) state.pendingFoods.splice(state.pendingFoods.indexOf(food), 1)
  },

  updateFood(state, payload) {
    let food = state.foods.find(f => f.id == payload.id)
    if (food) Vue.set(state.foods, state.foods.indexOf(food), payload)
  },

  insertFood(state, payload) {
    state.foods.unshift(payload)
  },

  insertPendingFoods(state, payload) {
    state.pendingFoods.unshift(payload)
  },

  removeFood(state, payload) {
    let food = state.foods.find(f => f.id == payload.id)
    if (food) state.foods.splice(state.foods.indexOf(food), 1)
  },

  addCategory(state, payload) {
    let category = state.categories.find(c => c.id == payload.id)
    if (category) Vue.set(state.categories, state.categories.indexOf(category), payload)
    else state.categories.push(payload)
  },

  setCategories(state, payload) {
    state.categories = []

    if (payload.size) {
      payload.forEach(doc => {
        let category = doc.data()
        category.id = doc.id
        category.ref = doc.ref
        state.categories.push(category)
      })
    }

    state.status.gettingCategories = false
  },

  removeCategory(state, payload) {
    let category = state.categories.find(c => c.id == payload.id)
    if (category) state.categories.splice(state.categories.indexOf(category), 1)
  },

  addToApproving(state, payload) {
    if (!state.status.approving.find(a => a.id == payload.id))
      state.status.approving.push(payload)
  },
  
  removeFromApproving(state, payload) {
    let food = state.status.approving.find(a => a.id == payload.id)
    if (food) state.status.approving.splice(state.status.approving.indexOf(food), 1)
  },

  updateCategories(state, payload) {
    payload.foods.forEach(id => {
      let food = state.foods.find(f => f.id == id)
      Vue.set(food, 'category', payload.category)
    })
  },

  updateFoodField(state, payload) {
    Vue.set(payload.food, payload.field, payload.value)
  },

  emptyFoods(state) {
    state.foods = []
  },

  addReport(state, payload) {
    let report = state.reports.find(r => r.id == payload.id)
    if (report) Vue.set(state.reports, state.reports.indexOf(report), payload)
    else state.reports.push(payload)
  },

  removeReport(state, payload) {
    let report = state.reports.find(r => r.id == payload.id)
    state.reports.splice(state.reports.indexOf(report), 1)
  },

  addToHiddenFoods(state, payload) {
    if (!state.hiddenFoods.find(f => f.id == payload))
      state.hiddenFoods.push(payload)
  },

  removeHiddenFood(state, payload) {
    let food = state.hiddenFoods.find(f => f.id == payload.id)
    if (food) state.hiddenFoods.splice(state.hiddenFoods.indexOf(food), 1)
  },

  addToPendingFoods (state, payload) {
    if (!state.pendingFoods.find(f => f.id == payload))
      state.pendingFoods.push(payload)
  },
  updatedDefault(state, payload) {
    state.status.updatingDefault = payload
  },
  addingColumn(state, payload) {
    state.status.adding = payload
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET FOODS
   *----------------------------------------------------------------------------*/
  getFoods({ commit, dispatch, rootState }) {
    commit('updateStatus', { getting: true })
    commit('updateStatus', { firstLoad: true })
    let user = rootState.user.user
    
    if (user) {
      let task = db.collection('foods')
      
      if (user.role == 'dietitian' || user.role == 'nutritionist' || user.role == 'naturopath')
        task = task.where('createdBy', '==', user.id)

      task = task.where('status', '==', 'published')
                 .orderBy('name', 'asc')
      
      if (state.status.lastVisible)
        task = task.startAfter(state.status.lastVisible)
      
      task
      .limit(100).get()
      .then(snapshot => {
        commit('addFoods', snapshot)
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { getting: false })
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * GET FOODS FOR FORM
   *----------------------------------------------------------------------------*/
  getFoodsForForm({ commit, dispatch }) {

    let task = db.collection('foods')
    
    task = task.where('status', '==', 'published')
                .orderBy('name', 'asc')
    
    task
    .get()
    .then(snapshot => {
      commit('addFoodNames', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { getting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET FOOD
   *
   * @params
   *  id: String
   *----------------------------------------------------------------------------*/
  getFood({ state, commit }, id) {
    if (!state.foods.find(f => f.id == id)) {
      db.collection('foods')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let food = doc.data()
          food.id = doc.id
          food.ref = doc.ref
          commit('addFood', food)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * SAVE FOOD
   *----------------------------------------------------------------------------*/
  saveFood({ commit, dispatch, rootState }, food) {
    commit('updateStatus', { saving: true })
    let foodRef = db.collection('foods')
    let user = firebase.auth().currentUser
    const { role, firstName } = rootState.user.user

    if (food.id) foodRef = foodRef.doc(food.id)
    else {
      foodRef = foodRef.doc()
      food.src = 'Custom'
      food.createdBy = user.uid
      food.status = 'pending'
    }

    foodRef.set(omit(omitBy(food, isUndefined), ['ref', 'id']))
    .then(() => {
      if (food.id) commit('updateFood', food)
      else {
        food.ref = foodRef
        food.id = foodRef.id
        commit('insertPendingFoods', food)
      }
      
      commit('updateStatus', { saving: false })

      // Send an email if he/she is a Dietitian
      if(role === 'dietitian' || role === 'nutritionist' || role === 'naturopath') {
        let data = {
          recipient: user.email,
          subject: 'Food submitted for review',
          message: `Your food "<strong>${food.name}</strong>" has been submitted for review. We'll notify you once approved.`
        }

        dispatch('mail/notify', data, { root: true })

        // Email to Chris
        dispatch('mail/added', {firstName , mealType: 'Food', mealName: food.name}, { root: true })

      }

      dispatch('showSuccess', 'Food saved', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { saving: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE FOOD
   *----------------------------------------------------------------------------*/
  async deleteFood({ commit, dispatch }, food) {
    commit('updateStatus', { deleting: true })
    
    await food.ref.delete()
    .then(() => {
      commit('removeFood', food)
      commit('updateStatus', { deleting: false })
      dispatch('showSuccess', 'Food deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deleting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * ADD CATEGORY
   *----------------------------------------------------------------------------*/
  async saveCategory({ commit, dispatch }, categoryData) {
    commit('updateStatus', { addingCategory: true })
    let data = {}
    Object.assign(data, categoryData)

    let docRef = db.collection('food_categories')

    if (data.id) docRef = docRef.doc(data.id)
    else docRef = docRef.doc()
    
    let user = firebase.auth().currentUser
    data.created = firebase.firestore.Timestamp.now()
    data.user = user.uid
    
    await docRef.set(omit(data, ['id', 'ref']))
    .then(() => {
      data.ref = docRef
      data.id = docRef.id
      commit('addCategory', data)
      commit('updateStatus', { addingCategory: false })
      dispatch('showSuccess', 'Category saved', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { addingCategory: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET FOOD CATEGORIES
   *----------------------------------------------------------------------------*/
  getCategories({ commit, dispatch }) {
    commit('updateStatus', { gettingCategories: true })
    commit('updateStatus', { categoriesFirstLoad: true })
    
    db.collection('food_categories')
    .get().then(snapshot => {
      commit('setCategories', snapshot)
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('updateStatus', { gettingCategories: false })
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE CATEGORY
   *----------------------------------------------------------------------------*/
  async deleteCategory({ commit, dispatch }, category) {
    commit('updateStatus', { deletingCategory: true })
    
    await category.ref
    .delete()
    .then(() => {
      commit('removeCategory', category)
      commit('updateStatus', { deletingCategory: false })
      dispatch('showSuccess', 'Category deleted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { deletingCategory: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET PENDING FOODS
   *----------------------------------------------------------------------------*/
  getPendingFoods({ commit, dispatch, rootState }) {
    commit('updateStatus', { gettingPendingFoods: true })
    commit('updateStatus', { firstPendingLoad: true })
    let user = rootState.user.user

    if (user) {
      let task = db.collection('foods')

      if (user.role == 'dietitian' || user.role == 'nutritionist' || user.role == 'naturopath')
        task = task.where('createdBy', '==', user.id)  

      task = task.where('status', '==', 'pending')
                 .orderBy('name', 'asc')
      
      if (state.status.lastPendingVisible) {
        task = task.startAfter(state.status.lastPendingVisible)
      }
      
      task
      .limit(20).get()
      .then(snapshot => {
        commit('addPendingFoods', snapshot)
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { gettingPendingFoods: false })
        dispatch('showError', error.message, { root: true })
      })
    }
  },
 
  /*------------------------------------------------------------------------------
   * GET HIDDEN FOODS
   *----------------------------------------------------------------------------*/
  getHiddenFoods({ commit, dispatch, rootState }) {
    commit('updateStatus', { gettingHiddenFoods: true })
    commit('updateStatus', { firstHiddenLoad: true })
    let user = rootState.user.user

    if (user) {
      let task = db.collection('foods')

      task = task.where('status', '==', 'hidden')
                 .orderBy('name', 'asc')
      
      if (state.status.lastHiddenVisible) {
        task = task.startAfter(state.status.lastHiddenVisible)
      }
      
      task
      .limit(100).get()
      .then(snapshot => {
        commit('setHiddenFoods', snapshot)
      })
      .catch(error => {
        console.log(error.message)
        commit('updateStatus', { gettingHiddenFoods: false })
        dispatch('showError', error.message, { root: true })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * APPROVE 
   *----------------------------------------------------------------------------*/
  approveFood({ commit, dispatch }, food) {
    commit('addToApproving', food)

    food.ref
    .update({ status: 'published' })
    .then(() => {
      commit('removeFromPending', food)
      commit('removeFromApproving', food)

      if(food?.createdBy) {
        // Get the user who created
        // the food
        db.collection('users')
        .doc(food.createdBy)
        .get()
        .then(doc => {
          if (doc.exists) {
            const { role, email } = doc.data()
            // Send an email if he/she is a Dietitian
            if(role === 'dietitian' || role === 'nutritionist' || role === 'naturopath') {
              let data = {
                recipient: email,
                subject: 'Food Approved',
                message: `Your food "<strong>${food.name}</strong>" has been approved!`
              }

              dispatch('mail/notify', data, { root: true })
            }
          }
        })
        .catch(error => {
          console.log(error.message)
          dispatch('showError', error.message, { root: true })
        })
      }

      dispatch('showSuccess', 'Food approved', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * BULK UPDATE CATEGORIES
   * @params
   * - Array foods
   * - String category
   *----------------------------------------------------------------------------*/
  async bulkUpdateCategories({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    let batch = db.batch()
    
    data.foods.forEach(food => {
      let ref = db.collection('foods').doc(food)
      batch.update(ref, { category: data.category })
    })
    
    await batch.commit()
    .then(() => {
      commit('updateCategories', data)
      commit('updateStatus', { updating: false })
      dispatch('showSuccess', 'Foods updated', { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * BULK DELETE FOODS
   *----------------------------------------------------------------------------*/
  async bulkDeleteFoods({ commit, dispatch }, ids) {
    commit('updateStatus', { deleting: true })
    let batch = db.batch()
    
    ids.forEach(id => {
      let ref = db.collection('foods').doc(id)
      batch.delete(ref)
    })
    
    await batch.commit()
    .then(() => {
      commit('updateStatus', { deleting: false })
      dispatch('showSuccess', 'Foods deleted', { root: true })
 
      ids.forEach(id => {
        commit('removeFood', { id })
      })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE FOOD DOCUMENT FIELD
   * 
   * @params
   *  Object
   *    food: Object
   *    field: String
   *    value: String
   *    message: String (optional)
   *    silent: Boolean (optional)
   *----------------------------------------------------------------------------*/
  async updateFoodField({ commit, dispatch }, data) {
    commit('updateStatus', { updating: true })
    
    await data.food.ref
    .update({ 
      [data.field]: data.value, 
      updated: firebase.firestore.Timestamp.now()
    })
    .then(() => {
      commit('updateFoodField', data)
      commit('updateStatus', { updating: false })
      if (!data.silent) 
        dispatch('showSuccess', data.message || 'Food updated', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { updating: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * REPORT FOOD
   *
   * @params
   *  Object
   *    food: Object
   *    message: String
   *----------------------------------------------------------------------------*/
  async reportFood({ commit, dispatch, rootState }, data) {
    commit('updateStatus', { reporting: true })
    const { firstName } = rootState.user.user
    let user = firebase.auth().currentUser
    
    let fooData = {
      food: data.food.id,
      message: data.message,
      created: firebase.firestore.Timestamp.now(),
      status: 'pending',
      user: user.uid
    }

    await db.collection('food_reports')
    .add(fooData)
    .then(() => {
      commit('updateStatus', { reporting: false })

      // Get the user who created
      // the food
      db.collection('users')
      .doc(data.food.createdBy)
      .get()
      .then(doc => {
        if (doc.exists) {
          const { role, email } = doc.data()
          // Send an email if he/she is a Dietitian
          if(role === 'dietitian' || role === 'nutritionist' || role === 'naturopath') {
            let emailData = {
              recipient: email,
              subject: 'Food Reported',
              message: `Your food "<strong>${data.food.name}</strong>" has been reported. We will notify you with the updates as soon as we've resolved it.`
            }

            dispatch('mail/notify', emailData, { root: true })

            // Email to Chris
            dispatch('mail/reported', { firstName , mealType: 'Food', mealName: data.food.name }, { root: true })
          }
        }
      })
      .catch(error => {
        console.log(error.message)
        dispatch('showError', error.message, { root: true })
      })
      dispatch('showSuccess', 'Report submitted', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { reporting: false })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET REPORTED FOODS
   *----------------------------------------------------------------------------*/
  getReportedFoods({ commit, dispatch }) {
    db.collection('food_reports')
    .where('status', '==', 'pending')
    .onSnapshot(snapshot => {
      if (snapshot.size) {
        snapshot.forEach(doc => {
          let report = doc.data()
          report.id = doc.id
          report.ref = doc.ref
          commit('addReport', report)
          dispatch('getFood', report.food)
          dispatch('members/getMember', report.user, { root: true })
        })
      }
      
      commit('updateStatus', { reportsFirstLoad: true })
    })
  },

  /*------------------------------------------------------------------------------
   * MARK REPORT AS RESOLVED
   *----------------------------------------------------------------------------*/
  markReportAsResolved({ commit, dispatch }, report) {
    commit('updateStatus', { resolving: report.id })
    
    report.ref
    .update({ status: 'resolved' })
    .then(() => {
      commit('removeReport', report)
      commit('updateStatus', { resolving: null })
      dispatch('showSuccess', 'Report marked as resolved', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updateStatus', { resolving: null })
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE DEFAULT MEASUREMENT
   *----------------------------------------------------------------------------*/
  updateDefaultMeasurement({ commit, dispatch }, { food, defaultMeasurement, defaultWeight }) {
    commit('updatedDefault', true)

    console.log(defaultMeasurement, defaultWeight)

    food.ref.update({ defaultMeasurement, defaultWeight })
    .then(() => {
      commit('updatedDefault', false)
      dispatch('showSuccess', 'Set as default measurement!', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      commit('updatedDefault', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE DEFAULT MEASUREMENT
   *----------------------------------------------------------------------------*/
  createColumn({ commit }, { data, food }) {
    return new Promise((resolve, reject) => {
      commit('addingColumn', true)

      const name = data.name.toLowerCase()

      food.ref.update({ [name]: data })
      .then(() => {
        commit('addingColumn', false)
        resolve()
      })
      .catch(error => {
        console.log(error.message)
        reject()
        commit('addingColumn', false)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}