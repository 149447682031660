import db from '@/firebase/init'
import Vue from 'vue'
import _omit from 'lodash/omit'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/
const state = {
  data: {},
  status: {
    saving: false,
    getting: false,
    error: null,
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  gettingState(state, bol) {
    state.status.getting = bol
  },

  setData(state, payload) {
    let data

    try {
      data = Vue.prototype.$formatData(payload)
    }
    catch {
      data = payload
    }

    state.data = data
    state.status.getting = false
  },

  savingState(state, bol) {
    state.status.saving = bol
  },

  setError(state, message) {
    state.status.error = message
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET SETTINGS
   *----------------------------------------------------------------------------*/
  getSettings({ commit, dispatch }) {
    commit('gettingState', true)

    db.collection('settings')
    .limit(1).get()
    .then(snapshot => {
      if (snapshot.size) {
        let doc = snapshot.docs[0]
        commit('setData', doc)
      }
      else {
        commit('gettingState', false)
      }
    })
    .catch(error => {
      commit('gettingState', false)
      dispatch('showError', error.message, { root: true })
      console.log(error.message)
    })
  },

  /*------------------------------------------------------------------------------
   * SAVE SETTINGS
   *----------------------------------------------------------------------------*/
  saveSettings({ state, commit, dispatch }) {
    commit('savingState', true)
    if (state.status.error) commit('setError', null)
    let data = state.data
    let ref

    if (data.id) ref = db.collection('settings').doc(data.id)
    else ref = db.collection('settings').doc()

    ref.set(_omit(data, ['ref', 'id']))
    .then(() => {
      data.id = ref.id
      data.ref = ref
      commit('setData', data)
      commit('savingState', false)
      dispatch('showSuccess', 'Settings updated.', { root: true })
    })
    .catch(error => {
      console.log(error.message)
      dispatch('showError', error.message, { root: true })
      commit('savingState', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}