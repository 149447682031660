import _ from 'lodash';
import Vue from 'vue';
import router from '@/router';
import firebase from 'firebase';
import db from '@/firebase/init';
import { loadStripe } from '@stripe/stripe-js';

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const state = {
  plans: [],
  cards: [],
  invoices: [],
  stampPrices: [],
  subscriptions: [],
  data: {
    active: true,
  },
  status: {
    error: null,
    dialog: false,
    getting: false,
    creating: false,
    deleting: false,
    updating: false,
    firstLoad: false,
    cancelling: false,
    subscribing: false,
    paymentError: null,
    gettingPlans: false,
    gettingSession: false,
  },
};

/*------------------------------------------------------------------------------
 * GETTERS
 *----------------------------------------------------------------------------*/
const getters = {
  actives: function (state) {
    if (state.plans) {
      return state.plans.filter((plan) => {
        return plan.active;
      });
    } else {
      return [];
    }
  },

  price: (state) => (id) => {
    if (state.subscriptions) {
      let price = state.subscriptions.find((p) => p.id == id);
      return price || {};
    }
  },

  plan: (state) => (id) => {
    if (state.plans) {
      let plan = state.plans.find((p) => p.id == id);
      return plan || {};
    }
  },
};

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  setPlans(state, payload) {
    state.plans = [];

    payload.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      data.ref = doc.ref;

      if (!state.plans.find((p) => p.id == data.id)) state.plans.push(data);
    });

    state.status.gettingPlans = false;
  },

  insertPlan(state, payload) {
    let plan = state.plans.find((plan) => plan.id == payload.id);
    if (!plan) state.plans.push(payload);
    else Vue.set(state.plans, state.plans.indexOf(plan), payload);
  },

  removePlan(state, plan) {
    state.plans.splice(state.plans.indexOf(plan), 1);
  },

  setData(state, plan) {
    state.data = Object.assign({}, plan);
  },

  resetData(state) {
    state.data = {
      recurring: 'yes',
      active: true,
    };
  },

  reorderPlans(state, payload) {
    state.plans = payload;
  },

  addSubscription(state, payload) {
    if (!state.subscriptions.find((s) => s.id == payload.id))
      state.subscriptions.push(payload);
  },

  clearSubscriptions(state) {
    state.subscriptions = [];
  },

  updateStatus(state, payload) {
    state.status[Object.keys(payload)[0]] = Object.values(payload)[0];
  },

  setPrices(state, payload) {
    if (payload.size) {
      payload.forEach((doc) => {
        let price = doc.data();
        price.id = doc.id;
        price.ref = doc.ref;
        state.subscriptions.push(price);
      });
    }

    state.status.getting = false;
  },

  setStampPrices(state, doc) {
    state.stampPrices.push(doc);
  },

  setInvoices(state, payload) {
    state.invoices = payload;
  },

  setCards(state, payload) {
    state.cards = payload;
  },

  updatePlanField(state, payload) {
    Vue.set(payload.plan, payload.field, payload.value);
  },
};

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/
const actions = {
  /*------------------------------------------------------------------------------
   * GET SUBSCRIPTIONS
   *----------------------------------------------------------------------------*/
  async getSubscriptions({ commit, dispatch }) {
    commit('updateStatus', { getting: true });

    await db
      .collection('subscriptions')
      .where('stripe_metadata_app', '==', 'mealzee')
      .get()
      .then(async (snapshot) => {
        if (snapshot.size) {
          // let product = snapshot.docs[0];
          snapshot.forEach(product => {
            product.ref
              .collection('prices')
              .get()
              .then((priceSnapshot) => {
                if (priceSnapshot.size) commit('setPrices', priceSnapshot);
                else commit('updateStatus', { getting: false });
              });

          })
        } else {
          commit('updateStatus', { getting: false });
        }
      })
      .catch((error) => {
        console.log(error.message);
        commit('updateStatus', { getting: false });
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * GET STAMP PRICES
   *----------------------------------------------------------------------------*/
  async getStampPrices({ commit, dispatch }) {
    commit('updateStatus', { getting: true });

    await db
      .collection('subscriptions')
      .where('stripe_metadata_stamp', '==', 'true')
      .get()
      .then(async (snapshot) => {
        if (snapshot.size) {
          snapshot.forEach((product) => {
            product.ref
              .collection('prices')
              .get()
              .then((priceSnapshot) => {
                if (priceSnapshot.size)
                  commit('setStampPrices', {
                    id: product.id,
                    ref: product.ref,
                    ...product.data(),
                    price: Vue.prototype.$formatData(priceSnapshot.docs[0]),
                  });
                else commit('updateStatus', { getting: false });
              });
          });
        } else {
          commit('updateStatus', { getting: false });
        }
      })
      .catch((error) => {
        console.log(error.message);
        commit('updateStatus', { getting: false });
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * CREATE PLAN
   *----------------------------------------------------------------------------*/
  createPricing({ state, commit, dispatch }) {
    commit('updateStatus', { creating: true });
    if (state.status.error) commit('updateStatus', { error: null });
    let user = firebase.auth().currentUser;

    let data = Object.assign({}, state.data);
    data.createdBy = user.uid;
    data.createdAt = Date.now();
    data.updatedAt = Date.now();
    data.order = state.plans.length;
    data.subRef = '';

    db.collection('plans')
      .add(data)
      .then((docRef) => {
        commit('updateStatus', { dialog: false });
        commit('updateStatus', { creating: false });
        dispatch('showSuccess', 'Plan created.', { root: true });
        data.ref = docRef;
        data.id = docRef.id;
        commit('insertPlan', data);
      })
      .catch((error) => {
        commit('updateStatus', { error: error.message });
        commit('updateStatus', { creating: false });
      });
  },

  /*------------------------------------------------------------------------------
   * UPDATE PLAN
   *----------------------------------------------------------------------------*/
  update({ state, commit, dispatch }) {
    commit('updateStatus', { updating: true });

    let data = _.omit(state.data, ['id', 'ref']);
    data.updatedAt = Date.now();

    state.data.ref
      .update(data)
      .then(() => {
        commit('insertPlan', state.data);
        commit('updateStatus', { dialog: false });
        commit('updateStatus', { updating: false });
        dispatch('showSuccess', 'Plan updated.', { root: true });
      })
      .catch((error) => {
        commit('updateStatus', { updating: false });
        commit('updateStatus', { error: error.message });
      });
  },

  /*------------------------------------------------------------------------------
   * DELETE PLAN
   *----------------------------------------------------------------------------*/
  async delete({ commit, dispatch }, plan) {
    commit('updateStatus', { deleting: true });

    await plan.ref
      .delete()
      .then(() => {
        commit('removePlan', plan);
        commit('updateStatus', { deleting: false });
        dispatch('showSuccess', 'Plan successfully deleted.', { root: true });
      })
      .catch((error) => {
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * ACTIVATE/DEACTIVATE PLAN
   *----------------------------------------------------------------------------*/
  updatePlanStatus({ dispatch }, plan) {
    plan.ref
      .update({ active: plan.active })
      .then(() => {
        dispatch('showSuccess', 'Plan status updated.', { root: true });
      })
      .catch((error) => {
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * GET PLANS
   *----------------------------------------------------------------------------*/
  async getPlans({ commit, dispatch }) {
    commit('updateStatus', { gettingPlans: true });

    await db
      .collection('plans')
      .orderBy('order', 'asc')
      .get()
      .then((snapshot) => {
        commit('setPlans', snapshot);
      })
      .catch((error) => {
        console.log(error.message);
        commit('updateStatus', { gettingPlans: false });
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * GET PLAN
   *----------------------------------------------------------------------------*/
  async getPlan({ commit, dispatch }, id) {
    await db
      .collection('plans')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let plan = doc.data();
          plan.ref = doc.ref;
          plan.id = doc.id;
          commit('insertPlan', plan);
        }
      })
      .catch((error) => {
        dispatch('showError', error.message, { root: true });
      });
  },

  /*------------------------------------------------------------------------------
   * SORT PLANS AND PRICING
   *----------------------------------------------------------------------------*/
  sortPlans({ dispatch }, sorted) {
    let count = 0;

    sorted.forEach((plan, i) => {
      plan.ref
        .update({ order: i })
        .then(() => {
          count++;

          if (sorted.length == count)
            dispatch('showSuccess', 'Plan sorting successful.', { root: true });
        })
        .catch((error) => {
          dispatch('showError', error.message, { root: true });
        });
    });
  },

  /*------------------------------------------------------------------------------
   * SUBSCRIBE
   *----------------------------------------------------------------------------*/
  async subscribe({ commit, dispatch, getters }, plan) {
    commit('updateStatus', { subscribe: false });
    dispatch('showLoading', true, { root: true });
    let currentUser = firebase.auth().currentUser;

    let data = {
      price: plan.subscription,
      allow_promotion_codes: true,
      success_url: `${window.location.origin}${router.resolve({ name: 'Subscription' }).href
        }`,
      cancel_url: `${window.location.origin}${router.resolve({ name: 'Subscription' }).href
        }`,
      subscription_data: {
        trial_settings: {
          end_behavior: {
            missing_payment_method: 'cancel',
          },
        },
        trial_period_days: 7,
      },
      metadata: {
        plan: plan.id,
      },
    };

    if (getters.price(plan.subscription).type == 'one_time')
      data.mode = 'payment';

    const docRef = await db
      .collection('customers')
      .doc(currentUser.uid)
      .collection('checkout_sessions')
      .add(data);

    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        console.log(error.message);
        commit('updateStatus', { subscribe: false });
        dispatch('showError', error.message, { root: true });
      }
      if (sessionId) {
        commit('updateStatus', { subscribe: false });
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  },

  /*------------------------------------------------------------------------------
   * CANCEL SUBSCRIPTION
   *----------------------------------------------------------------------------*/
  async cancelSubscription({ commit }, sub) {
    commit('updateStatus', { cancelling: true });

    const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);

    await stripe.subscriptions.del(sub.id).then(() => {
      commit('updateStatus', { cancelling: false });
    });
  },

  /*------------------------------------------------------------------------------
   * GET PRICE BY REFERENCE
   *----------------------------------------------------------------------------*/
  async getPriceByRef({ commit }, price) {
    await price
      .get()
      .then((doc) => {
        if (doc.exists) {
          let price = doc.data();
          price.ref = doc.ref;
          price.id = doc.id;
          commit('addSubscription', price);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  },

  /*------------------------------------------------------------------------------
   * GET INVOICES BY CUSTOMER
   *----------------------------------------------------------------------------*/
  getInvoices({ commit }) {
    let user = firebase.auth().currentUser;

    db.collection('customers')
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          var getFunc = firebase.functions().httpsCallable('getInvoices');

          getFunc({ customer: data.stripeId })
            .then((invoices) => {
              commit('setInvoices', invoices.data.data);
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      });
  },

  /*------------------------------------------------------------------------------
   * lIST CUSTOMER CARDS
   *----------------------------------------------------------------------------*/
  getCards({ commit }) {
    let user = firebase.auth().currentUser;

    db.collection('customers')
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          var getFunc = firebase.functions().httpsCallable('listAllCards');

          getFunc({ customer: data.stripeId })
            .then((cards) => {
              commit('setCards', cards.data.data);
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      });
  },

  /*------------------------------------------------------------------------------
   * GET CUSTOMER PORTAL
   *----------------------------------------------------------------------------*/
  async getCustomerPortal({ commit }) {
    let user = firebase.auth().currentUser;
    commit('updateStatus', { gettingSession: true });

    await db
      .collection('customers')
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let data = doc.data();
          var getFunc = firebase.functions().httpsCallable('customerPortal');

          getFunc({
            customer: data.stripeId,
            url: `${window.origin}${router.resolve({ name: 'Subscription' }).href
              }`,
          })
            .then((session) => {
              window.open(session.data.url, '_self');
              commit('updateStatus', { gettingSession: false });
            })
            .catch((error) => {
              console.log(error.message);
              commit('updateStatus', { gettingSession: false });
            });
        }
      });
  },

  /*------------------------------------------------------------------------------
   * UPDATE PLAN FIELD
   *
   * @params
   *  Object
   *    plan: Object
   *    field: String
   *    value: Any
   *    message: String (optional)
   *    silent: Boolean (optional) - disable success message
   *----------------------------------------------------------------------------*/
  async updatePlanField({ commit, dispatch }, data) {
    await data.plan.ref
      .update({
        [data.field]: data.value,
        updated: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        if (!data.silent)
          dispatch('showSuccess', data.message || 'Plan updated', {
            root: true,
          });
        commit('updatePlanField', data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
