import measurements from '@/json/measurements.json'
import db from '@/firebase/init'
import _ from 'lodash'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const state = {
  measurements: [],
  measurement: null,
  lastVisible: null,
  searchResult: [],
  data: {
    'Public Food Key' : null,
    units: []
  },
  search: {
    term: null,
  },
  status: {
    getting: false,
    loadingMore: false,
    firstLoad: false,
    updating: false,
    adding: false,
    searching: false,
    deleting: [],
  }
}

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/
const mutations = {
  setGettingState(state, bol) {
    state.status.getting = bol
  },

  setMeasurements(state, payload) {
    payload.forEach(item => {
      let data = item.data()
      data.id = item.id
      data.ref = item.ref

      if (!state.measurements.find(measurement => measurement.id == data.id)) {
        state.measurements.push(data)
      }
    })

    state.status.getting = false
    state.status.loadingMore = false

    if (!state.status.firstLoad) {
      state.status.firstLoad = true
    }
  },

  setLastVisible(state, doc) {
    state.lastVisible = doc
  },

  setLoadingMoreState(state, bol) {
    state.status.loadingMore = bol
  },

  setData(state, payload) {
    state.data = payload
  },

  insertUnit(state) {
    if (!state.data.units) state.data.units = []

    state.data.units.push({
      description: null,
      grams: null,
      mls: null,
    })
  },

  removeUnit(state, unit) {
    state.data.units.splice(state.data.units.indexOf(unit), 1)
  },

  setUpdatingState(state, bol) {
    state.status.updating = bol
  },

  setAddingState(state, bol) {
    state.status.adding = bol
  },

  setSearchingState(state, bol) {
    state.status.searching = bol
  },

  setSearchResult(state, payload) {
    if (payload) {
      let data = payload.data()
      data.id = payload.id
      data.ref = payload.ref
      state.searchResult.push(data)
    }
    else {
      state.searchResult = []
    }
  },

  addToDeleting(state, id) {
    state.status.deleting.push(id)
  },

  removeFromDeleting(state, id) {
    state.status.deleting.splice(id)
  },

  removeMeasurement(state, id) {
    state.measurements.splice(state.measurements.indexOf(id), 1)
    state.searchResult.splice(state.measurements.indexOf(id), 1)
  },

  setMeasurement(state, payload) {
    let data = payload.data()
    data.id = payload.id
    data.ref = payload.ref

    state.measurement = data
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {
  /*------------------------------------------------------------------------------
   * GET MEASUREMENTS
   *----------------------------------------------------------------------------*/
  getMeasurements({ commit, dispatch }) {
    if (!state.lastVisible) commit('setGettingState', true)
    else commit('setLoadingMoreState', true)
    let query

    if (!state.lastVisible) {
      query = db.collection('measurements')
      .limit(50)
      .get()
    }
    else {
      query = db.collection('measurements')
      .startAfter(state.lastVisible)
      .limit(50)
      .get()
    }

    query.then(snapshot => {
      if (snapshot.size) {
        commit('setLastVisible', snapshot.docs[snapshot.docs.length - 1])
        commit('setMeasurements', snapshot)
      }
      else {
        commit('setGettingState', false)
        commit('setLoadingMoreState', false)
      }
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('setGettingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * UPLOAD MEASUREMENTS
   *----------------------------------------------------------------------------*/
  uploadMeasurements() {
    measurements.forEach(item => {
      let data = {
        'Public Food Key': item['Public Food Key'],
        units: [
          {
            'description' : item['description'],
            'grams': item['grams'],
            'mls': item['mLs'],
            'derivation': item['Derivation'],
            'measureDerivationDescription': item['Measure derivation description']
          }
        ]
      }

      db.collection('measurements')
      .where('Public Food Key', '==', data['Public Food Key'])
      .limit(1)
      .get()
      .then(snapshot => {
        if (!snapshot.size) {
          db.collection('measurements').add(data)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    })
  },

  /*------------------------------------------------------------------------------
   * UPDATE MEASUREMENT
   *----------------------------------------------------------------------------*/
  updateMeasurement({ state, dispatch, commit }) {
    commit('setUpdatingState', true)

    state.data.ref.update(_.omit(state.data, ['id', 'ref']))
    .then(() => {
      commit('setUpdatingState', false)
      dispatch('showSuccess', 'Measurement successfully updated.', { root: true })
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('setUpdatingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * ADD MEASUREMENT
   *----------------------------------------------------------------------------*/
  async addMeasurement({ state, commit, dispatch }) {
    commit('setAddingState', true)

    await db.collection('measurements')
    .where('Public Food Key', '==', state.data['Public Food Key'])
    .limit(1)
    .get()
    .then(snapshot => {
      if (!snapshot.size) {
        return db.collection('measurements').add(state.data)
        .then(() => {
          commit('setAddingState', false)
          dispatch('showSuccess', 'Measurement successfully added.', { root: true })
        })
      }
      else {
        dispatch('showError', 'Public Food Key already exists.', { root: true })
        commit('setAddingState', false)
      }
    })
    .catch(error => {
      commit('setAddingState', false)
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * SEARCH MEASUREMENT
   *----------------------------------------------------------------------------*/
  search({ state, commit, dispatch }) {
    commit('setSearchingState', true)

    db.collection('measurements')
    .where('Public Food Key', '==', state.search.term)
    .limit(1)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setSearchResult', snapshot.docs[0])
      }
      else {
        dispatch('showError', 'No measurement found.', { root: true })
      }

      commit('setSearchingState', false)
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
      commit('setSearchingState', false)
    })
  },

  /*------------------------------------------------------------------------------
   * DELETE MEASUREMENT
   *----------------------------------------------------------------------------*/
  delete({ commit, dispatch }, item) {
    commit('addToDeleting', item.id)

    item.ref.delete()
    .then(() => {
      commit('removeFromDeleting', item.id)
      commit('removeMeasurement', item.id)
      dispatch('showSuccess', 'Measurement successfully deleted.', { root: true })
    })
    .catch(error => {
      dispatch('showError', error.message, { root: true })
    })
  },

  /*------------------------------------------------------------------------------
   * GET MEASUREMENT
   *----------------------------------------------------------------------------*/
  getMeasurementByFoodKey({ commit }, key) {
    commit('setGettingState', true)

    db.collection('measurements')
    .where('Public Food Key', '==', key)
    .limit(1)
    .get()
    .then(snapshot => {
      if (snapshot.size) {
        commit('setMeasurement', snapshot.docs[0])
      }

      commit('setGettingState', false)
    })
    .catch(error => {
      console.log(error.message)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
