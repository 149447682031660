import './filters'
import Vue from 'vue'
import './components'
import './prototypes'
import '@/firebase/init'
import App from './App.vue'
import store from './store'
import router from './router'
import VueMeta from 'vue-meta'
import firebase from 'firebase'
import VueCookies from 'vue-cookies'
// import * as Sentry from "@sentry/vue";
import vuetify from './plugins/vuetify';
import VueClipboard from 'vue-clipboard2'
// import { Integrations } from "@sentry/tracing";

import 'intro.js/minified/introjs.min.css'


Vue.use(VueMeta)
Vue.use(VueCookies)
Vue.use(VueClipboard)

VueClipboard.config.autoSetContainer = true
Vue.config.productionTip = false
Vue.$cookies.config('7d')

// Sentry.init({
//   Vue,
//   dsn: "https://14c992420ad64c2099636ed5f5771adb@o1038223.ingest.sentry.io/6006426",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "app.mealzee.com", /^\//],
//     }),
//   ],
//   // Set trace sSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

let app = null

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})
